import { IConfig } from 'config';
import { ECryptoType, EStableType, EToken } from 'types/currency';

export const polygon: IConfig = {
    network: {
        name: 'Mumbai Testnet',
        chainId: '0x13881',
        rpcUrl: 'https://matic-mumbai.chainstacklabs.com',
        blockExplorerUrl: 'https://mumbai.polygonscan.com/',
        symbol: 'Matic',
        symbolDigits: 18,
    },
    appName: 'Brightpool',
    timeSettlementValues: [1, 3, 5, 7, 14, 28], // in days
    ledgerManager: process.env.REACT_APP_LEDGER_MANAGER || '',
    nativeTokenAddress: process.env.REACT_APP_NATIVE_TOKEN || '',
    nativeTokenName: 'BRI',
    currency: [
        {
            name: EStableType.TUSD,
            address: '0x0aCCB71D3215d63C784dC9985CD8F64E64b997CE',
            type: EToken.STABLE,
        },
        {
            name: ECryptoType.TETH,
            address: '0x8e1877cBB895278fbE04309608DbB11AfF7fC1c3',
            type: EToken.CRYPTO,
        },
    ],
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
};
