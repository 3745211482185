import React, { useEffect } from 'react';

export const StokChart: React.FC = () => {
    useEffect(() => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        new (window as any).TradingView.widget({
            autosize: true,
            symbol: 'ETHUSDT',
            interval: 'D',
            timezone: 'Etc/UTC',
            theme: 'dark',
            style: '1',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            // withdateranges: true,
            // hide_top_toolbar: true,
            hide_legend: true,
            hide_side_toolbar: false,
            // allow_symbol_change: true,
            save_image: false,
            container_id: 'tradingview_f69bb',
            show_popup_button: true,
        });
    }, []);
    return <div id="tradingview_f69bb" style={{ height: '100%' }}></div>;
};
