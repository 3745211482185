import { ErrorBoundary } from 'components/ErrorBoundary';
import { OrderBook } from 'modules/orderBook/components/OrderBook';
import { StokChart } from 'modules/stockChart';
import { SwapForm } from 'modules/swap/components/SwapForm';
import { SwapContextWrapper } from 'modules/swap/context';
import React from 'react';
import { isMobile } from 'react-device-detect';

// TODO refactor it to be used within domain (like Staking?)
export const Home: React.FC = () => {
    return (
        <div className="mx-auto w-full">
            <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-10">
                <div className="dark:bg-darkpool-dimmed mx-auto max-w-xl rounded-lg p-5 w-full col-span-7 lg:p-8 lg:max-w-2xl">
                    <div
                        style={{
                            height: +`${isMobile ? 320 : 720}`,
                        }}
                    >
                        <ErrorBoundary>
                            <StokChart />
                        </ErrorBoundary>
                    </div>
                </div>
                <div className="w-full box">
                    <SwapContextWrapper>
                        <SwapForm />
                    </SwapContextWrapper>
                </div>
            </div>
            <div className="max-w-xl mx-auto mt-8 lg:max-w-none">
                <OrderBook />
            </div>
        </div>
    );
};
