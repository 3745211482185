import { TCurrencyPair } from 'types/currency';

export enum ETransactionType {
    SELL = 'c',
    BUY = 'p',
}

export interface ILoadDPXReward {
    currency: TCurrencyPair;
    volume: number;
    strike: number;
    orderTime: number;
    type: ETransactionType;
}
