import axios from 'axios';
import { config } from 'config';
import { TCurrencyPair } from 'types/currency';
import { ILoadDPXReward } from 'types/DTO';
import { ITransaction } from 'types/transaction';
import { formatDecimal } from 'utils/numbers';

export interface IPrepareSignature {
    order: {
        deadline: number;
        endsInSec: number;
        order: {
            askAmount: string;
            askAsset: string;
            id: number;
            isPut: boolean;
            offerAmount: string;
            offerAsset: string;
            owner: string;
        };
    };
    reward: string;
    signature: string;
}

export const loadReward = async ({
    currency,
    volume,
    strike,
    orderTime,
    type,
}: ILoadDPXReward): Promise<number> => {
    try {
        const response = await axios.post<{ price: number; id: number }>(
            `${config[0].baseUrl}/reward`,
            {
                id: 13,
                currency,
                volume,
                strike,
                orderTime,
                type,
            }
        );

        return response.data.price;
    } catch (err) {
        return Promise.resolve(0);
    }
};

export const getSignatureWithReward = async ({
    askAsset,
    askAmount,
    offerAsset,
    offerAmount,
    owner,
    settlementTime,
    deadline,
    isPut,
}: ITransaction): Promise<Nullable<IPrepareSignature>> => {
    const data = {
        order: {
            askAsset,
            askAmount,
            offerAsset,
            offerAmount,
            owner,
            isPut,
        },
        endsInSec: settlementTime,
        deadline,
    };
    try {
        const response = await axios.post<IPrepareSignature>(
            `${config[0].baseUrl}/prepare-signature`,
            data
        );
        return response.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getCurrencyPrice = async (): Promise<Record<
    TCurrencyPair,
    number
> | null> => {
    try {
        const response = await axios.get<Record<TCurrencyPair, string>>(
            `${config[0].baseUrl}/spot`
        );
        const mapped = {} as Record<TCurrencyPair, number>;
        for (const [key, value] of Object.entries(response.data)) {
            mapped[key as TCurrencyPair] = formatDecimal(+value, 2);
        }
        return mapped;
    } catch (err) {
        console.log(err);
        return null;
    }
};
