import { getMyHistory, getOrderHistory } from 'modules/orderBook/api';
import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useQuery } from 'react-query';
import { EOrderBookMode, IOrderBook } from 'types/orderBook';
import { DesktopOrderBook } from '../DesktopOrderBook';
import { MobileOrderBook } from '../MobileOrderBook';
import { OrderBookToggle } from '../OrderBookToggle';
import { useSigner } from 'hooks/useSigner';

export const OrderBook: React.FC = () => {
    const [orderMode, setOrderMode] = useState(EOrderBookMode.MY_TRANSACTION);
    const { address } = useSigner();

    const orderBook =
        orderMode === EOrderBookMode.ALL_MARKET
            ? useQuery<IOrderBook[]>(['todos'], getOrderHistory, {
                  staleTime: 10 * 1000,
              })
            : useQuery<IOrderBook[]>(
                  ['todo', address],
                  () => getMyHistory(address),
                  { enabled: !!address, staleTime: 10 * 1000 }
              );

    const handleClickCancelTransation = () => {
        console.log('cancel tx');
    };

    const handleClickGetTransaction = () => {
        console.log('get transaction');
    };

    return (
        <div>
            <OrderBookToggle onClick={setOrderMode} active={orderMode} />
            <MobileView>
                {orderBook.data?.map((el) => (
                    <div key={el.id}>
                        <MobileOrderBook
                            {...el}
                            onCancel={handleClickCancelTransation}
                            getTransaction={handleClickGetTransaction}
                        />
                    </div>
                ))}
            </MobileView>
            <BrowserView>
                <DesktopOrderBook orderBook={orderBook.data || []} />
            </BrowserView>
        </div>
    );
};
