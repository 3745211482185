import React from 'react';
import { IntlProvider } from 'react-intl';
import { en } from '../translations/en';

export const LanguageProvider: React.FC = ({ children }) => {
    return (
        <IntlProvider messages={en} locale="en" defaultLocale="en">
            {children}
        </IntlProvider>
    );
};
