import { TCryptoCurrency, TStableCurrency } from 'config';
import { useConfig } from 'config/context';
import {
    ECryptoType,
    EStableType,
    EToken,
    TCurrencyType,
} from 'types/currency';
import { discriminate } from 'utils/discriminate';

export const useCurrency = (): {
    stableToken: TStableCurrency[];
    cryptoToken: TCryptoCurrency[];
    allTokenNames: TCurrencyType[];
    stableNames: EStableType[];
    cryptoNames: ECryptoType[];
    getAddressByName: (name: TCurrencyType) => string;
    getNameByAddress: (address: string) => TCurrencyType | 'n/a';
} => {
    const { currency } = useConfig();

    const stableToken = currency.filter(discriminate('type', EToken.STABLE));
    const cryptoToken = currency.filter(discriminate('type', EToken.CRYPTO));
    const stableNames = stableToken.map((el) => el.name);
    const cryptoNames = cryptoToken.map((el) => el.name);
    const allTokenNames = [...stableNames, ...cryptoNames];
    const getAddressByName = (name: TCurrencyType) =>
        currency.find((el) => el.name === name)?.address || '';

    const getNameByAddress = (address: string): TCurrencyType | 'n/a' =>
        currency.find((el) => el.address === address)?.name || 'n/a';

    return {
        stableToken,
        cryptoToken,
        allTokenNames,
        stableNames,
        cryptoNames,
        getAddressByName,
        getNameByAddress,
    };
};
