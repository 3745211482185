import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TableHeader: React.FC = () => {
    return (
        <thead>
            <tr className="border-b border-darkpool-dimmed">
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-40">
                    <FormattedMessage id="tradingPair" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-16">
                    <FormattedMessage id="type" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-28">
                    <FormattedMessage id="createdDate" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-28">
                    <FormattedMessage id="expirationDate" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-28">
                    <FormattedMessage id="amount" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal w-auto">
                    <FormattedMessage id="price" />
                </th>
                <th className="text-sm py-3 text-left px-2 text-darkpool-lightGray font-normal">
                    <FormattedMessage id="briReward" />
                </th>
                <th className="w-16"></th>
                <th className="w-16"></th>
            </tr>
        </thead>
    );
};
