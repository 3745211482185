export const discriminate = <K extends PropertyKey, V extends string>(
    discriminantKey: K,
    discriminantValue: V
) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return <T extends Record<K, any>>(
        obj: T & Record<K, V extends T[K] ? T[K] : V>
    ): obj is Extract<T, Record<K, V>> =>
        obj[discriminantKey] === discriminantValue;
};
