import {
    ECryptoType,
    EStableType,
    EToken,
    TCurrencyType,
} from 'types/currency';
import { polygon } from './production';
import { smartChain } from './testCI';
import { mumbai } from './development';

enum Env {
    Production = 'production',
    Development = 'development',
    TestAllNetwork = 'test-all-networks',
    TestCI = 'test-CI',
}
export interface IConfig {
    network: {
        name: string;
        chainId: string;
        rpcUrl: string;
        blockExplorerUrl: string;
        symbol: string;
        symbolDigits: number;
    };
    appName: string;
    timeSettlementValues: number[];
    ledgerManager: string;
    currency: TAllCurrency[];
    nativeTokenAddress: string;
    nativeTokenName: string;
    baseUrl: string;
}
type TAllCurrency = TStableCurrency | TCryptoCurrency;

export interface Currency {
    name: TCurrencyType;
    address: string;
    type: EToken;
}

export type TCryptoCurrency = {
    type: EToken.CRYPTO;
    name: ECryptoType;
    address: string;
};

export type TStableCurrency = {
    type: EToken.STABLE;
    name: EStableType;
    address: string;
};

const envConfig: {
    [key in Env]: IConfig[];
} = {
    [Env.Development]: [mumbai],
    [Env.TestCI]: [smartChain],
    [Env.TestAllNetwork]: [mumbai],
    [Env.Production]: [polygon],
};

const env: Env = (process.env.REACT_APP_ENV as Env) || Env.Production;

export const config = envConfig[env];
