import React, { useState } from 'react';
import { SwapButton } from '../../../components/SwapButton';
import { InternalSwapState } from './InternalSwapState';

export const SwapModeToggle: React.FC<{
    left: string;
    right: string;
    onChange: (mode: InternalSwapState) => void;
}> = ({ left, right, onChange }) => {
    const [selected, setSelected] = useState<InternalSwapState>(
        InternalSwapState.Left
    );

    return (
        <div>
            <div className="w-full p-1 bg-black flex rounded-full border border-darkpool-light1">
                <SwapButton
                    title={left}
                    onClick={() => {
                        if (selected === InternalSwapState.Left) {
                            return;
                        }
                        setSelected(InternalSwapState.Left);
                        onChange(InternalSwapState.Left);
                    }}
                    selected={selected === InternalSwapState.Left}
                />
                <SwapButton
                    title={right}
                    onClick={() => {
                        if (selected === InternalSwapState.Right) {
                            return;
                        }
                        setSelected(InternalSwapState.Right);
                        onChange(InternalSwapState.Right);
                    }}
                    selected={selected === InternalSwapState.Right}
                />
            </div>
        </div>
    );
};
