import React, { useEffect, useRef } from 'react';
import './slider.css';

interface IProps {
    value: number;
    onChange: (value: number) => void;
    sliderValues: number[];
    minus?: boolean;
    step?: number;
}

export const Slider: React.FC<IProps> = ({
    value,
    onChange,
    sliderValues,
    minus,
    step = 1,
}) => {
    const inputRef = useRef<HTMLInputElement>(null); // nosemgrep

    const handleChange = (el: React.ChangeEvent<HTMLInputElement> | number) => {
        if (typeof el === 'number') {
            onChange(el);
            return;
        }
        onChange(el.currentTarget.valueAsNumber);
    };

    useEffect(() => {
        const rangeSum =
            Math.abs(sliderValues[0]) +
            Math.abs(sliderValues[sliderValues.length - 1]);

        const calculatePercent = minus
            ? ((rangeSum - Math.abs(value)) / rangeSum) * 100
            : (value / rangeSum) * 100;

        if (inputRef.current) {
            inputRef.current.style.setProperty(
                '--webkitProgressPercent',
                `${calculatePercent}%`
            );
        }
    }, [value, sliderValues]);

    return (
        <div>
            <div className="relative pb-10">
                <div className="relative px-2">
                    <div
                        className="absolute w-full right-0"
                        style={{ top: '6px' }}
                    >
                        <div className="flex justify-between w-full">
                            {sliderValues.map((el) => (
                                <button
                                    key={el}
                                    id="grapBtn"
                                    className={`button rounded-full w-4 h-4 relative ${
                                        el <= value
                                            ? 'dark:bg-darkpool-green'
                                            : 'dark:bg-black'
                                    }`}
                                >
                                    <div className="percentValues">
                                        {`${el} %`}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                    <input // nosemgrep
                        type="range"
                        min={sliderValues[0]}
                        max={sliderValues[sliderValues.length - 1]}
                        step={step}
                        ref={inputRef}
                        value={value}
                        onChange={handleChange}
                        className="rounded-md absolute right-0 top-1"
                    />
                </div>
            </div>
        </div>
    );
};
