import { WalletContext } from 'modules/wallet/context';
import React, { useContext } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { shortenAddress } from 'utils/text';
import logo from '../../../assets/logo.png';
import mobileLogo from '../../../assets/mobileLogo.png';
import styles from './header.module.scss';
const menu = [
    {
        id: 1,
        label: 'Trade',
        path: '/',
        icon: '',
    },
    {
        id: 2,
        label: 'Dashboard',
        path: '/dashboard',
        icon: '',
    },
    {
        id: 3,
        label: 'Stake',
        path: '/staking',
        icon: '',
    },
];

export const Header: React.FC = () => {
    const {
        isConnected,
        isValidNetwork,
        connectWallet,
        switchNetwork,
        address,
    } = useContext(WalletContext);

    return (
        <div className="flex justify-between items-center h-12 px-4 mt-6 mb-12">
            <div>
                <NavLink to="/">
                    <MobileView>
                        <img src={mobileLogo} alt="logo" />
                    </MobileView>
                    <BrowserView>
                        <img src={logo} alt="logo" />
                    </BrowserView>
                </NavLink>
            </div>
            <nav>
                <BrowserView>
                    <ul className="flex gap-x-8">
                        {menu.map(({ id, label, path }) => (
                            <li
                                key={id}
                                className="hover:text-darkpool-green cursor-pointer"
                            >
                                <NavLink
                                    to={path}
                                    className={({ isActive }) =>
                                        isActive ? styles.active : ''
                                    }
                                >
                                    {label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </BrowserView>
                <MobileView>
                    <ul
                        className="flex gap-12 py-3 px-8 fixed bottom-1 bg-darkpool-dimmed rounded-full"
                        style={{
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                        }}
                    >
                        {menu.map(({ id, label, path }) => (
                            <li
                                key={id}
                                className="hover:text-darkpool-green cursor-pointer"
                            >
                                <NavLink
                                    to={path}
                                    className={({ isActive }) =>
                                        isActive ? styles.active : ''
                                    }
                                >
                                    {label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </MobileView>
            </nav>
            <div className="flex gap-3 bor">
                {isConnected && !isValidNetwork && (
                    <button
                        className="btn bg-red-400 dark:bg-red-400 rounded text-black py-2 px-4"
                        onClick={switchNetwork}
                    >
                        <FormattedMessage id="switchNetwork" />
                    </button>
                )}
                {!isConnected && (
                    <button
                        onClick={connectWallet}
                        className="px-6 py-3 text-black font-bold rounded-lg uppercase"
                    >
                        <BrowserView>
                            <FormattedMessage id="connectToWallet" />
                        </BrowserView>
                        <MobileView>
                            <FormattedMessage id="connect" />
                        </MobileView>
                    </button>
                )}
                {isConnected && isValidNetwork && address && (
                    <button className="px-6 py-3 text-black font-bold rounded-lg uppercase">
                        {shortenAddress(address)}
                    </button>
                )}
            </div>
        </div>
    );
};
