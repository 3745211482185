import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Close } from './icons/Close.svg';

export const Modal: React.FC<{
    title?: string;
    close: () => void;
}> = ({ title, close, children }) => {
    return (
        <div className="fixed left-0 top-0 pin z-50 w-screen min-h-screen bg-black bg-opacity-50">
            <div className="absolute w-full h-full overflow-y-auto grid">
                <div className="max-w-lg m-auto bg-darkpool-dimmed rounded-2xl">
                    <div className="flex justify-between border-b border-darkpool-lighter">
                        <div className="font-bold text-2xl text-darkpool-lighterGray p-6 ">
                            {title && <FormattedMessage id={title} />}
                        </div>
                        <button
                            className="close dark:bg-transparent p-6 "
                            onClick={close}
                        >
                            <Close className="text-darkpool-lighterGray font-sm" />
                        </button>
                    </div>
                    <div className="p-6 ">{children}</div>
                </div>
            </div>
        </div>
    );
};
