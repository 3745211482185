import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EOrderBookMode } from 'types/orderBook';

interface IProps {
    onClick: (mode: EOrderBookMode) => void;
    active: EOrderBookMode;
}

export const OrderBookToggle: React.FC<IProps> = ({ onClick, active }) => {
    return (
        <div className="flex border-b-2 border-darkpool-dimmed">
            <span
                onClick={() => onClick(EOrderBookMode.MY_TRANSACTION)}
                style={{ marginBottom: '-2px' }}
                className={`w-1/2 flex justify-center py-3 border-b-2 border-darkpool-dimmed font-bold cursor-pointer lg:w-auto lg:px-5  ${
                    active === EOrderBookMode.MY_TRANSACTION
                        ? 'border-darkpool-green text-darkpool-green'
                        : ''
                }`}
            >
                <FormattedMessage id="myOrders" />
            </span>
            <span
                onClick={() => onClick(EOrderBookMode.ALL_MARKET)}
                style={{ marginBottom: '-2px' }}
                className={`w-1/2 flex justify-center py-3 border-b-2 border-darkpool-dimmed font-bold cursor-pointer lg:w-auto lg:px-5 ${
                    active === EOrderBookMode.ALL_MARKET
                        ? 'border-darkpool-green text-darkpool-green'
                        : ''
                }`}
            >
                <FormattedMessage id="marketOrders" />
            </span>
        </div>
    );
};
