import React from 'react';
import { IOrderBook } from 'types/orderBook';
import { Row } from './Row';
import { TableHeader } from './TableHeader';

interface IProps {
    orderBook: IOrderBook[];
}

export const DesktopOrderBook: React.FC<IProps> = ({ orderBook }) => {
    return (
        <div className="mt-8">
            <table className="table-fixed w-full">
                <TableHeader />

                {orderBook.map((el) => (
                    <Row key={el.id} order={el} /> // nosemgrep
                ))}
            </table>
        </div>
    );
};
