import { ISwapStateAccessor } from './ISwapStateAccessor';

export class BookAccessor implements ISwapStateAccessor {
    public GetApr(): // NOTE: Temporary ignore value
    // reward: number,
    // price: number,
    // settlement: number,
    // amount: number
    number {
        // NOTE: Temporary ignore value
        // return (reward * price * (365 / settlement)) / amount || 0;
        return 0;
    }
}
