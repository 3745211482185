import { Spinner } from 'components/spinner';
import { useConfig } from 'config/context';
import { ethers } from 'ethers';
import { useCurrency } from 'hooks/useCurrency';
import { useSigner } from 'hooks/useSigner';
import { LedgerContract } from 'modules/wallet/utils/LedgerContract';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import erc20abi from 'types/raw/Erc20.abi.json';
import { ITransaction } from 'types/transaction';
import { dayToSecond, formatDecimal, numberTostring } from 'utils/numbers';
import { Modal } from '../../../components/Modal';
import { getSignatureWithReward, IPrepareSignature } from '../api';
import { SwapContext } from '../context';
import { InternalSwapState } from './InternalSwapState';

export const ConfirmationModal: React.FC<{
    askAmount: number;
    offerAmount: number;
    close: () => void;
    confirm: () => void;
}> = ({ close, confirm, askAmount, offerAmount }) => {
    const [approved, setApproved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signatureData, setSignatureData] =
        useState<null | IPrepareSignature>(null);
    const swapContext = useContext(SwapContext);
    const { ledgerManager } = useConfig();
    const { signer, address } = useSigner();
    const { getAddressByName } = useCurrency();
    const intl = useIntl();

    const handleApproveToken = async () => {
        setLoading(true);
        try {
            const settlementTimeToSecond = dayToSecond(swapContext.settlement);

            let transaction: ITransaction = {
                id: '0',
                askAsset: getAddressByName(swapContext.cryptoCurrency),
                askAmount: numberTostring(askAmount),
                offerAsset: getAddressByName(swapContext.priceCurrency),
                offerAmount: numberTostring(offerAmount),
                owner: address,
                settlementTime: settlementTimeToSecond,
                deadline: 121223123123123,
                isPut: true,
            };

            if (swapContext.mode === InternalSwapState.Right) {
                transaction = {
                    ...transaction,
                    askAsset: getAddressByName(swapContext.priceCurrency),
                    offerAsset: getAddressByName(swapContext.cryptoCurrency),
                    isPut: false,
                };
            }
            const signatureData = await getSignatureWithReward(transaction);
            if (!signatureData) {
                throw new Error('siganture is empty');
            }
            setSignatureData(signatureData);

            const erc20 = new ethers.Contract(
                signatureData.order.order.offerAsset,
                erc20abi,
                signer
            );
            const approve = await erc20.approve(
                ledgerManager,
                signatureData.order.order.offerAmount,
                { gasLimit: 999999 }
            );
            await approve.wait();

            setApproved(true);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleBuyToken = async () => {
        setLoading(true);

        if (!signatureData) {
            throw new Error('siganture is empty');
        }
        try {
            const ledger = new LedgerContract(signer, ledgerManager);
            const tx = await ledger.addOrder(
                signatureData.order.order,
                signatureData.order.endsInSec,
                signatureData.order.deadline,
                signatureData.reward,
                signatureData.signature
            );
            await tx.wait();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            confirm();
            swapContext.resetForm();
        }
    };

    const formatDay = (day: number) => {
        if (day === 1) {
            return `${day} ${intl.formatMessage({ id: 'day' })}`;
        }
        return `${day} ${intl.formatMessage({ id: 'days' })}`;
    };

    const getCalculateAmount = (): number => {
        if (swapContext.mode === InternalSwapState.Left) {
            return formatDecimal(swapContext.amount);
        }
        return formatDecimal(swapContext.amount * swapContext.price);
    };

    const getCurrency = () => {
        if (swapContext.mode === InternalSwapState.Left) {
            return swapContext.cryptoCurrency;
        }
        return swapContext.priceCurrency;
    };

    return (
        <Modal title="confirmOrder" close={close}>
            <div className="w-96">
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="type" />
                    </span>

                    <span
                        className={`font-bold ${
                            swapContext.mode === InternalSwapState.Right
                                ? 'text-darkpool-red'
                                : 'text-darkpool-green'
                        }`}
                    >
                        {swapContext.mode}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="amount" />
                    </span>

                    <span className="text-white font-bold">
                        {swapContext.mode === InternalSwapState.Right
                            ? `${offerAmount} ${swapContext.cryptoCurrency}`
                            : `${askAmount} ${swapContext.cryptoCurrency}`}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="rate" />
                    </span>

                    <span className="text-white font-bold">
                        {swapContext.mode === InternalSwapState.Right
                            ? `${askAmount} ${swapContext.priceCurrency}`
                            : `${offerAmount} ${swapContext.priceCurrency}`}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="expiresIn" />
                    </span>

                    <span className="text-white font-bold">
                        {formatDay(swapContext.settlement)}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="minimumReceived" />
                    </span>

                    <span className="text-white font-bold">
                        {`~${getCalculateAmount()} ${getCurrency()}`}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="yourReward" />
                    </span>

                    <span className="text-white font-bold">
                        {swapContext.reward}
                    </span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="slippage" />
                    </span>

                    <span className="text-white font-bold">0.3%</span>
                </div>
                <div className="flex justify-between mt-2">
                    <span className="text-darkpool-lightGray">
                        <FormattedMessage id="transactionFee" />
                    </span>

                    <span className="text-white font-bold">0%</span>
                </div>
            </div>
            {!approved && (
                <button
                    onClick={handleApproveToken}
                    className="cursor-pointer w-full mt-6 text-black p-3 rounded-full font-bold uppercase"
                >
                    <div className="flex justify-center gap-2">
                        {loading && <Spinner />}
                        <FormattedMessage id="confirmOrder" />
                    </div>
                </button>
            )}
            {approved && (
                <button
                    onClick={handleBuyToken}
                    className="cursor-pointer w-full mt-6 text-black p-3 rounded-full font-bold uppercase"
                >
                    <div className="flex justify-center gap-2">
                        {loading && <Spinner />}
                        {swapContext.mode === InternalSwapState.Right ? (
                            <FormattedMessage id="sellTokens" />
                        ) : (
                            <FormattedMessage id="buyTokens" />
                        )}
                    </div>
                </button>
            )}
            <button
                onClick={close}
                className="cursor-pointer w-full mt-3 dark:bg-transparent p-3 border border-darkpool-green rounded-full text-darkpool-green uppercase font-bold"
            >
                <FormattedMessage id="cancel" />
            </button>
        </Modal>
    );
};
