import { useCurrency } from 'hooks/useCurrency';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import React, { useContext, useEffect, useState } from 'react';
import { ECryptoType, EStableType, TCurrencyPair } from 'types/currency';
import { ILoadDPXReward } from 'types/DTO';
import { useDebounce } from '../../hooks/useDebounce';
import { WalletContext } from '../wallet/context';
import { loadReward } from './api';
import { BuyAccessor } from './BuyAccessor';
import { BetAccessor } from './components/BetAccessor';
import { BookAccessor } from './components/BookAccessor';
import { InternalSwapState } from './components/InternalSwapState';
import { ISwapStateAccessor } from './components/ISwapStateAccessor';
import { ISwapAccessor } from './ISwapAccessor';
import { SellAccessor } from './SellAccessor';

export const defaultSwapValues = {
    amount: 0,
    cryptoCurrency: ECryptoType.TETH,
    price: 0,
    priceCurrency: EStableType.TUSD,
    settlement: 1,
    percentAmount: 0,
    percentPrice: 0,
    mode: InternalSwapState.Left,
    swapState: InternalSwapState.Left,
    reward: 0,
    pair: `${ECryptoType.TETH}${EStableType.TUSD}` as TCurrencyPair,
    sliderValuesPrice: [-15, -12.5, -10, -7.5, -5, -2.5, 0],
    sliderValuesAmount: [0, 25, 50, 75, 100],
    priceSelect: [EStableType.TUSD],
    amountSelect: [ECryptoType.TETH],
    setMode: (_value: InternalSwapState): void => {
        return;
    },
    setSwapState: (_value: InternalSwapState): void => {
        return;
    },
    setPercentAmount: (_value: number): void => {
        return;
    },
    setPercentPrice: (_value: number): void => {
        return;
    },
    setSettlement: (_value: number): void => {
        return;
    },
    setAmount: (_amount: number): void => {
        return;
    },
    setPrice: (_amount: number): void => {
        return;
    },
    setCryptoCurrency: (_amount: ECryptoType): void => {
        return;
    },
    setPriceCurrency: (_amount: EStableType): void => {
        return;
    },
    resetForm: (): void => {
        return;
    },
    getActualPrice: (): number => {
        return 0;
    },
    getMinPrice: (): number => {
        return 0;
    },
    getAskAmount: (): number => {
        return 0;
    },
    getCurrency: (): ECryptoType | EStableType => {
        return ECryptoType.TETH;
    },
    getApr: (): number => {
        return 0;
    },
    getSwapType: (): string => {
        return '';
    },
};

export const SwapContext = React.createContext(defaultSwapValues);

export const SwapContextWrapper: React.FC = ({ children }) => {
    const walletContext = useContext(WalletContext);
    const buyAccessor = new BuyAccessor();
    const sellAccessor = new SellAccessor();
    const [accessor, setAccessor] = useState<ISwapAccessor>(buyAccessor);
    const bookAccessor = new BookAccessor();
    const betAccessor = new BetAccessor();
    const [swapStateAccessor, setSwapStateAccessor] =
        useState<ISwapStateAccessor>(bookAccessor);
    const { cryptoNames, stableNames, cryptoToken, stableToken } =
        useCurrency();

    const [settlement, setSettlement] = useState(1);
    const [cryptoCurrency, setCryptoCurrency] = useState<ECryptoType>(
        cryptoToken[0]?.name
    );
    const [priceCurrency, setPriceCurrency] = useState<EStableType>(
        stableToken[0]?.name
    );

    const [amount, setAmount] = useState(0);
    const [pair, setPair] = useState<TCurrencyPair>(
        accessor.GetPair(cryptoToken[0]?.name, stableToken[0]?.name)
    );
    const [percentAmount, setPercentAmount] = useState(0);
    const [percentPrice, setPercentPrice] = useState(0);
    const [reward, setReward] = useState(0);
    const [price, setPrice] = useState<number>(0);
    const [mode, setMode] = useState(InternalSwapState.Left);
    const [swapState, setSwapState] = useState(InternalSwapState.Right);

    const [sliderValuesPrice, setSliderValuesPrice] = useState([
        -15, -12.5, -10, -7.5, -5, -2.5, 0,
    ]);
    const [sliderValuesAmount, _setSliderValuesAmount] = useState([
        0, 25, 50, 75, 100,
    ]);
    const [priceSelect, setPriceSelect] = useState<EStableType[]>(stableNames);
    const [amountSelect, setAmountSelect] =
        useState<ECryptoType[]>(cryptoNames);

    const debouncedForm = useDebounce(`${price}${amount}${settlement}`, 500);

    async function getReward() {
        if (!price || !amount) {
            return;
        }

        const type = accessor.GetTransactionType();
        const data: ILoadDPXReward = {
            currency: accessor.GetPair(cryptoCurrency, priceCurrency),
            volume: amount,
            strike: price,
            orderTime: settlement,
            type,
        };
        try {
            const reward = await loadReward(data);
            setReward(+reward.toFixed(4));
        } catch (err) {
            console.log(err);
        }
    }

    function resetPrice() {
        const price = accessor.GetPrice(walletContext, pair);
        setPrice(price);
    }

    function refreshCurrency() {
        accessor.FetchCurrencies(walletContext, cryptoCurrency, priceCurrency);

        setTimeout(() => refreshCurrency(), 7000);
    }

    function resetForm() {
        resetPrice();
        setPercentPrice(0);
        setAmount(0);
        setPercentAmount(0);
        setSettlement(1);
        const sliderValues = accessor.GetSliderDefaultValues();
        setSliderValuesPrice(sliderValues);
    }

    useEffect(() => {
        const pair = accessor.GetPair(cryptoCurrency, priceCurrency);
        if (pair) {
            setPair(pair);
        }
        if (cryptoCurrency && priceCurrency) {
            refreshCurrency();
        }
    }, [cryptoCurrency, priceCurrency]);

    useEffect(() => {
        if (!cryptoCurrency && !priceCurrency) {
            const pair = accessor.GetPair(
                cryptoToken[0]?.name,
                stableToken[0]?.name
            );
            setPair(pair);
            setPriceCurrency(stableToken[0]?.name);
            setCryptoCurrency(cryptoToken[0]?.name);
            getActualPrice();
        }
    }, [cryptoToken, stableToken]);

    useEffect(() => {
        if (!priceSelect.length && !amountSelect.length) {
            setPriceSelect(stableNames);
            setAmountSelect(cryptoNames);
        }
    }, [cryptoNames, stableNames]);

    useEffect(() => {
        console.log('useEffect prices');
        if (Object.keys(walletContext.prices).length === 0) {
            return;
        }
        setPrice(accessor.GetPrice(walletContext, pair));
    }, [walletContext.prices]);

    useEffect(() => {
        if (walletContext.prices) {
            const amount = accessor.GetAmountToken(
                walletContext,
                cryptoCurrency,
                priceCurrency,
                percentPrice
            );
            setAmount(amount);
        }
    }, [walletContext.prices]);

    useDidMountEffect(() => {
        resetForm();
    }, [mode]);

    useEffect(() => {
        if (!debouncedForm || !price || !amount) {
            return;
        }
        getReward();

        const interval = setInterval(() => {
            getReward();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [debouncedForm]);

    const getActualPrice = () => {
        const pair = accessor.GetPair(cryptoCurrency, priceCurrency);
        return accessor.GetActualPrice(walletContext, pair);
    };

    const setModeOverride = (_value: InternalSwapState): void => {
        setMode(_value);
        setAccessor(
            _value === InternalSwapState.Left ? buyAccessor : sellAccessor
        );
    };

    const setSwapStateOverride = (_value: InternalSwapState): void => {
        setSwapState(_value);

        setSwapStateAccessor(
            _value === InternalSwapState.Left ? bookAccessor : betAccessor
        );
    };

    const getMinPrice = (): number => {
        const pair = accessor.GetPair(cryptoCurrency, priceCurrency);

        return accessor.GetMinPrice(walletContext, pair, sliderValuesPrice[0]);
    };

    function getAskAmountOverride(): number {
        return accessor.GetAskAmount(amount, price);
    }

    function getCurrencyOverride(): ECryptoType | EStableType {
        return accessor.GetCurrency(cryptoCurrency, priceCurrency);
    }

    function getAprOverride(): number {
        return swapStateAccessor.GetApr(reward, price, settlement, amount);
    }

    function getSwapTypeOverride(): string {
        return accessor.GetSwapType();
    }

    return (
        <SwapContext.Provider
            value={{
                reward,
                amount,
                pair,
                cryptoCurrency,
                mode,
                swapState,
                percentAmount,
                percentPrice,
                price,
                priceCurrency,
                priceSelect,
                amountSelect,
                sliderValuesPrice,
                sliderValuesAmount,
                setAmount,
                setCryptoCurrency,
                setMode: setModeOverride,
                setSwapState: setSwapStateOverride,
                setPercentAmount,
                setPercentPrice,
                setPrice,
                setPriceCurrency,
                setSettlement,
                settlement,
                resetForm,
                getActualPrice,
                getMinPrice,
                getAskAmount: getAskAmountOverride,
                getCurrency: getCurrencyOverride,
                getApr: getAprOverride,
                getSwapType: getSwapTypeOverride,
            }}
        >
            {children}
        </SwapContext.Provider>
    );
};
