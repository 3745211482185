export enum EToken {
    'STABLE' = 'STABLE',
    'CRYPTO' = 'CRYPTO',
}

export enum EStableType {
    'TUSD' = 'TUSD',
}

export enum ECryptoType {
    'TETH' = 'TETH',
    'BP1' = 'BP1',
    'DPX' = 'DPX',
    'MATIC' = 'MATIC',
    'WBTC' = 'WBTC',
}

export type TCurrencyType = EStableType | ECryptoType;
// export type TCryptoType =
export type TCurrencyPair = `${ECryptoType}${EStableType}`;

export type Portfolio = {
    [currency in TCurrencyType]?: number;
};

export type Prices = { [currency in TCurrencyPair]: number };
