import { ethers } from 'ethers';
import { erc20abi } from 'types/erc20.abi';
import { formatBigNumber } from './numbers';

export const getErc20Balance = async (
    address: string | undefined
): Promise<number> => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const userAddress = await provider?.getSigner()?.getAddress();

    if (!address) {
        console.error('ERROR: address dont exist for fetching erc20 balance');

        return 0;
    }

    if (!provider) {
        console.error('ERROR: provider not found, for fetching erc20 balance');
        return 0;
    }

    if (address === '0x0000000000000000000000000000000000000000') {
        const eth = await provider.getBalance(userAddress);
        return formatBigNumber(eth, 6);
    }

    const erc20 = new ethers.Contract(
        address.toLowerCase(),
        erc20abi,
        provider
    );

    const balance = await erc20.balanceOf(userAddress);

    if (balance === '0x00') {
        return 0;
    }

    return formatBigNumber(balance, 6);
};
