import { useConfig } from 'config/context';

export const useNativeToken = (): { address: string; name: string } => {
    const ctx = useConfig();

    return {
        address: ctx.nativeTokenAddress,
        name: ctx.nativeTokenName,
    };
};
