import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

export const calculateNewValue = (
    number: number,
    percentChange: number,
    relative = false
): number => {
    const percent = percentChange / 100;
    if (!relative) {
        return number * percent;
    }
    return number + number * percent;
};

export const dayToSecond = (day: number): number => {
    return 60 * 60 * 24 * day;
};

export const countDecimals = (num: number): number => {
    if (Math.floor(num) === num) return 0;
    return num.toString().split('.')[1].length || 0;
};

export const formatDecimal = (num: number, maxDecimal = 6): number => {
    if (!num) {
        return 0;
    }
    const decimal = countDecimals(num);
    if (decimal > maxDecimal) {
        const numToString = num.toString();
        return +numToString.slice(0, numToString.indexOf('.') + maxDecimal + 1);
    }
    return num;
};

export const roundNumber = (number: number): number => +number.toPrecision(2);

export const formatBigNumber = (
    number: BigNumber,
    displayDecimals = 18,
    decimals = 18
): number => {
    const remainder = number.mod(
        BigNumber.from(10).pow(decimals - displayDecimals)
    );
    return +formatUnits(number.sub(remainder), decimals);
};

export const numberTostring = (number: number, decimal = 18): string => {
    const num = Number(number * 10 ** decimal).toLocaleString('fullwide', {
        useGrouping: false,
    });
    return String(num);
};
