import React from 'react';

interface IProps {
    defaultValue: number;
    value?: number;
    max?: number;
    onChange?: (value: number) => void;
    suffix?: Array<string>;
    onSuffixChange?: (value: string) => void;
}

export const NumberInput: React.FC<IProps> = ({
    defaultValue,
    max,
    onChange,
    suffix,
    onSuffixChange,
    value,
}) => {
    return (
        <div>
            <div className="mt-1 relative rounded-md">
                <input
                    type="text"
                    name="price"
                    id="price"
                    className="block w-full py-4 text-xl font-bold pr-12 bg-transparent rounded-md"
                    placeholder="0"
                    min={0}
                    value={value}
                    max={max}
                    onChange={
                        onChange
                            ? (e) => onChange(Number(e.target.value) || 0)
                            : undefined
                    }
                    defaultValue={defaultValue}
                />
                {suffix && (
                    <div className="absolute inset-y-0 right-0 flex items-center">
                        <select
                            id="currency"
                            name="currency"
                            className="h-full py-0 pl-2 pr-12 text-xl font-bold border-l-0 bg-transparent rounded-l-none rounded-r-md"
                            onChange={
                                onSuffixChange
                                    ? (e) => onSuffixChange(e.target.value)
                                    : undefined
                            }
                        >
                            {suffix.map((v) => (
                                <option key={v} value={v}>
                                    {v}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
};
