import axios from 'axios';
import { config } from 'config';

export const getStakingAddresses = async (): Promise<Array<string>> => {
    try {
        const response = await axios.get<{
            addresses: Array<string>;
        }>(`${config[0].baseUrl}/stakings`);
        return response.data.addresses;
    } catch (err) {
        console.log(err);
        return [];
    }
};
