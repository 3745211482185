import { ECryptoType, EStableType } from '../../types/currency';
import { ETransactionType } from '../../types/DTO';
import { formatDecimal } from '../../utils/numbers';
import { IWalletContext } from '../wallet/context';
import { ISwapAccessor } from './ISwapAccessor';
import { WalletAccessor } from './WalletAccessor';

export class BuyAccessor extends WalletAccessor implements ISwapAccessor {
    constructor() {
        super();
    }

    public GetMinPrice(
        wallet: IWalletContext,
        pair: string,
        sliderValue: number
    ): number {
        return formatDecimal(
            this.GetActualPrice(wallet, pair) *
                (1 - Math.abs(sliderValue) / 100),
            2
        );
    }

    GetSliderDefaultValues(): number[] {
        return [-15, -12.5, -10, -7.5, -5, -2.5, 0];
    }

    public GetAmountToken(
        wallet: IWalletContext,
        cryptoCurrency: ECryptoType,
        stableCurrency: EStableType,
        percentAmount: number
    ): number {
        const actualPrice = this.GetActualPrice(
            wallet,
            this.GetPair(cryptoCurrency, stableCurrency)
        );
        const amountTokens =
            ((this.getBalanceStable(wallet, stableCurrency) / actualPrice) *
                percentAmount) /
            100;

        return formatDecimal(amountTokens);
    }

    public GetTransactionType(): ETransactionType {
        return ETransactionType.BUY;
    }

    GetAskAmount(amount: number, _: number): number {
        return formatDecimal(amount);
    }

    GetCurrency(
        cryptoCurrency: ECryptoType,
        _: EStableType
    ): ECryptoType | EStableType {
        return cryptoCurrency;
    }

    public GetSwapType(): string {
        return 'buy';
    }
}
