import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Layout } from 'components/Layout';
import { ConfigProvider } from 'config/context';
import { Staking } from 'modules/staking';
import { Home } from 'pages/Home';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.scss';
import { WalletProvider } from './modules/wallet/context';
import { LanguageProvider } from './utils/LanguageProvider';
import bcrypt from 'bcryptjs';

const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

export const App: React.FC = () => {
    const [logged, setLogged] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');

    const handleClickCheckPass = () => {
        const check = bcrypt.compareSync(
            password,
            '$2a$12$rs8O3JfRuJhlmj7K.D.Y4OzdV2sBxuzJ/8BEuNMPrYAdjfR95yOHu' // nosemgrep
        );
        if (check) {
            setLogged(true);
            return;
        }
        setPassword('');
        setError('password is incorrect');
    };
    return (
        <>
            {!logged ? (
                <div className="w-screen h-screen flex justify-center items-center">
                    <div className="flex flex-col">
                        <input
                            className="text-black p-2 rounded-md"
                            placeholder="password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                        <br />

                        <button
                            className={`p-2 text-black font-bold ${
                                password
                                    ? ''
                                    : 'dark:bg-gray-300 cursor-default'
                            }`}
                            disabled={!password}
                            onClick={handleClickCheckPass}
                        >
                            Login
                        </button>
                        <br />
                        {error}
                    </div>
                </div>
            ) : (
                <BrowserRouter>
                    <ApolloProvider client={client}>
                        <QueryClientProvider client={queryClient}>
                            <LanguageProvider>
                                <ConfigProvider>
                                    <WalletProvider>
                                        <Layout>
                                            <div className="pb-40">
                                                <Routes>
                                                    <Route
                                                        path="/"
                                                        element={<Home />}
                                                    ></Route>
                                                    <Route
                                                        path="/staking"
                                                        element={<Staking />}
                                                    ></Route>
                                                </Routes>
                                            </div>
                                        </Layout>
                                    </WalletProvider>
                                </ConfigProvider>
                            </LanguageProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </ApolloProvider>
                </BrowserRouter>
            )}
        </>
    );
};
