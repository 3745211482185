import { useNativeToken } from 'hooks/useNativeToken';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IStakingDetails } from '../types';

export const StakingStatistics: React.FC<{ activeStaking: IStakingDetails }> =
    ({ activeStaking: { currentAPR, stakingPoolSize, currentIndex } }) => {
        const { name: nativeTokenName } = useNativeToken();

        return (
            <div className="flex overflow-x-scroll gap-4 my-10 pb-2">
                <div className="flex-shrink-0 md:flex-grow w-64 bg-darkpool-dimmed rounded-2xl p-4">
                    <div>
                        <FormattedMessage id="currentAPR" />
                    </div>
                    <div className="text-2xl md:text-4xl mt-1 md:mt-3">
                        {Math.round(currentAPR * 100)}%
                    </div>
                </div>
                <div className="flex-shrink-0 md:flex-grow w-64 bg-darkpool-dimmed rounded-2xl p-4">
                    <div>
                        <FormattedMessage id="stakingPoolSize" />
                    </div>
                    <div className="text-2xl md:text-4xl mt-1 md:mt-3">
                        {stakingPoolSize} {nativeTokenName}
                    </div>
                </div>
                <div className="flex-shrink-0 md:flex-grow w-64 bg-darkpool-dimmed rounded-2xl p-4">
                    <div>
                        <FormattedMessage id="currentIndex" />
                    </div>
                    <div className="text-2xl md:text-4xl mt-1 md:mt-3">
                        {currentIndex.toString().slice(0, 5)} {nativeTokenName}
                    </div>
                </div>
            </div>
        );
    };
