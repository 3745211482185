import {
    BigNumberish,
    ethers,
    BytesLike as Arrayish,
    ContractTransaction,
} from 'ethers';
import { AddOrderRequest, ContractContext } from 'types/LedgerManager';
import { abi } from 'types/raw/LedgerManager.abi.json';

export class LedgerContract {
    contract: ContractContext;

    constructor(
        private readonly signer: ethers.Signer,
        private readonly address: string
    ) {
        this.address = address;

        this.contract = new ethers.Contract(
            this.address,
            abi,
            this.signer
        ) as unknown as ContractContext;
        return;
    }

    public addOrder = async (
        orderInfo: AddOrderRequest,
        endsInSec: BigNumberish,
        deadline: BigNumberish,
        rewardBaseAmount: BigNumberish,
        signature: Arrayish
    ): Promise<ContractTransaction> => {
        if (
            Number(orderInfo.askAmount) <= 0 ||
            Number(orderInfo.offerAmount) <= 0
        ) {
            throw new Error('Invalid price');
        }

        return await this.contract.addOrder(
            orderInfo,
            endsInSec,
            deadline,
            rewardBaseAmount,
            `0x${signature}`,
            { gasLimit: 999999 }
        );
    };
}
