import { Modal } from 'components/Modal';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const RulesModal: React.FC<{
    close: () => void;
    connectWallet: () => void;
}> = ({ close, connectWallet }) => {
    const [isAccepted, setIsAccepted] = useState(false);
    return (
        <Modal title="Connect wallet" close={close}>
            <div className="mb-12">
                {Header('01', 'readRules')}
                <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
                    <FormattedMessage id="read" />{' '}
                    <a href="" className="text-darkpool-accent">
                        <FormattedMessage id="termsOfService" />
                    </a>{' '}
                    <FormattedMessage id="and" />{' '}
                    <a href="" className="text-darkpool-accent">
                        <FormattedMessage id="privacyPolicy" />
                    </a>
                </span>
            </div>
            <div className="mb-12">
                {Header('02', 'acceptRules')}
                <div className="flex items-center mb-4">
                    <input
                        id="default-checkbox"
                        type="checkbox"
                        className="w-4 h-4 text-darkpool-accent bg-gray-100 rounded border-gray-300 focus:ring-darkpool-accent dark:focus:ring-darkpool-accent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={(e) => setIsAccepted(e.currentTarget.checked)}
                    />
                    <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        <FormattedMessage id="iAcceptTheTerms" />
                    </label>
                </div>
            </div>

            <div className="block mt-8 pb-4">
                <button
                    className={
                        'w-full text-black rounded-full py-3 font-bold ' +
                        (isAccepted
                            ? 'bg-darkpool-accent dark:bg-darkpool-accent'
                            : 'bg-gray-400 dark:bg-gray-400 text-gray-600 cursor-not-allowed')
                    }
                    disabled={!isAccepted}
                    onClick={connectWallet}
                >
                    <FormattedMessage id="connectWallet" />
                </button>
            </div>
        </Modal>
    );
};

const Header = (number: string, messageId: string) => {
    return (
        <div className="flex gap-4 mb-4 items-center">
            <div className="bg-darkpool-border rounded-full p-2 w-10 h-10 text-center ">
                {number}
            </div>
            <div className="">
                <FormattedMessage id={messageId} />
            </div>
        </div>
    );
};
