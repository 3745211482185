import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Info } from '../assets/info.svg';

export const InfoTooltip: React.FC<{ text: string }> = ({ text }) => {
    return (
        <div className="has-tooltip flex">
            <span className="tooltip ml-8 text-xs text-gray-400">
                <FormattedMessage id={text} />
            </span>
            <span className="ml-1">
                <Info />
            </span>
        </div>
    );
};
