import { useConfig, useConfigMethod } from 'config/context';
import { ethers } from 'ethers';
import { useCurrency } from 'hooks/useCurrency';
import { getCurrencyPrice } from 'modules/swap/api';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    EToken,
    Portfolio,
    TCurrencyPair,
    TCurrencyType,
} from 'types/currency';
import { getErc20Balance } from 'utils/getErc20Balance';
import { ErrorModal } from '../../components/ErrorModal';
import { isEthersError } from '../../types/error';
import { AddEthereumChainParameter } from '../../types/ethereum';
import { RulesModal } from './components/RulesModal';

// export to external variable for testing purposes - window.location.reload is readonly, so it's not possible to create stub of this function
window.reload = () => window.location.reload();

export interface IWalletContext {
    portfolio: Portfolio;
    prices: Record<TCurrencyPair, number>;
    isConnected: boolean;
    address: string;
    isValidNetwork: boolean;

    connectWallet: () => void;
    switchNetwork: () => void;

    getPortfolioAmount: (_currency: TCurrencyType) => number;

    fetchCurrency: (_address: string) => void;
    provider: ethers.providers.Web3Provider | undefined;
}

export const defaultContextValue = {
    portfolio: {} as Portfolio,
    prices: {} as Record<TCurrencyPair, number>,

    isConnected: false,
    address: '',
    isValidNetwork: false,

    connectWallet: (): void => {
        return;
    },
    switchNetwork: (): void => {
        return;
    },
    getPortfolioAmount: (_currency: TCurrencyType): number => {
        return 0;
    },

    fetchCurrency: (_address: string): void => {
        return;
    },

    provider: undefined as ethers.providers.Web3Provider | undefined,
} as IWalletContext;
export const WalletContext = React.createContext(defaultContextValue);

export const WalletProvider: React.FC = ({ children }) => {
    const { setConfigByNetwork, getFirstValidNetwork } = useConfigMethod();
    const { getNameByAddress } = useCurrency();
    const config = useConfig();
    const contextValue: typeof defaultContextValue = {
        ...defaultContextValue,
        provider: (window.ethereum &&
            new ethers.providers.Web3Provider(window.ethereum, 'any')) as
            | ethers.providers.Web3Provider
            | undefined,
    }; // clone default values

    const intl = useIntl();

    const [isConnected, setIsConnected] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [address, setAddress] = useState('');
    const [isValidNetwork, setIsValidNetwork] = useState(false);

    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isRulesModalVisible, setIsRulesModalVisible] = useState(false);

    const [portfolio, setPortfolio] = useState<Portfolio>({});
    const [prices, setPrices] = useState<Record<TCurrencyPair, number>>(
        {} as Record<TCurrencyPair, number>
    );

    const signer = contextValue.provider?.getSigner();

    const showError = (title: string, message: string) => {
        setErrorTitle(title);
        setErrorMessage(message);
        setIsErrorVisible(true);
    };

    const showUnhandledError = (error: unknown) => {
        if (error instanceof Error) {
            console.warn('Unhandled error: ' + JSON.stringify(error.message));
            showError(
                'error',
                `${isEthersError(error) ? `Code #${error.code}. ` : ''} ${
                    error.message
                }`
            );
        }
    };

    const connectWallet = async (acceptRules?: true) => {
        if (!contextValue.provider) {
            showError('Unsupported browser', 'error:noProviderDetected');
            return;
        }

        if (acceptRules === true) {
            setIsRulesModalVisible(false);
            localStorage.setItem('acceptedRules', 'true');
        }

        if (
            acceptRules !== true &&
            localStorage.getItem('acceptedRules') !== 'true'
        ) {
            setIsRulesModalVisible(true);
            return;
        }

        try {
            await contextValue.provider.send('eth_requestAccounts', []);
            await updateSignerData();
        } catch (error: unknown) {
            if ((error as { code: number }).code === 4001) {
                // EIP-1193 userRejectedRequest error
                showError(
                    'actionRejected',
                    intl.formatMessage({
                        id: 'error:walletConnectionRejected',
                    })
                );
            } else {
                showUnhandledError(error);
            }
        }
    };

    const switchToValidNetwork = async () => {
        if (!contextValue.provider) {
            showError('Unsupported browser', 'error:noProviderDetected');
            return;
        }
        try {
            const { network } = getFirstValidNetwork();
            await contextValue.provider
                .send('wallet_switchEthereumChain', [
                    { chainId: network.chainId },
                ])
                .catch((error: unknown) => {
                    console.log({ error });
                    if (isEthersError(error) && error.code === 4902) {
                        return addNetwork();
                    }
                    throw error;
                });
        } catch (error: unknown) {
            if (isEthersError(error) && error.code === 4001) {
                showError(
                    'actionRejected',
                    intl.formatMessage(
                        {
                            id: 'error:switchNetworkRejected',
                        },
                        { network: config.network.name }
                    )
                );
            } else {
                showUnhandledError(error);
            }
        }
    };

    const addNetwork = () =>
        // https://eips.ethereum.org/EIPS/eip-3085
        contextValue.provider?.send('wallet_addEthereumChain', [
            {
                chainName: config.network.name,
                chainId: config.network.chainId,
                rpcUrls: [config.network.rpcUrl],
                blockExplorerUrls: [config.network.blockExplorerUrl],
                nativeCurrency: {
                    decimals: config.network.symbolDigits,
                    name: config.network.symbol,
                    symbol: config.network.symbol,
                },
            } as AddEthereumChainParameter,
        ]);

    const updateSignerData = async () => {
        if (!contextValue.provider || !signer) {
            showError('Unsupported browser', 'error:noProviderDetected');
            return;
        }
        try {
            const signerAddress = await signer.getAddress();
            const connectNetwork = await contextValue.provider.getNetwork();
            const isValid = setConfigByNetwork(connectNetwork.chainId);

            setAddress(signerAddress);
            setIsConnected(true);

            setIsValidNetwork(isValid);
        } catch (error: unknown) {
            setAddress('');
            setIsConnected(false);
            setIsValidNetwork(false);

            console.warn(error);
        }
    };
    const fetchCurrency = async (address: string) => {
        const name = getNameByAddress(address);
        if (name === 'n/a') {
            return;
        }
        try {
            const amount = await getErc20Balance(address);

            if (portfolio[name] === amount) {
                return;
            }

            setPortfolio((prev) => ({ ...prev, [name]: amount }));
        } catch (err) {
            console.log('err', err);
        }
    };

    const fetchInitialPortfolio = async () => {
        if (!isValidNetwork) {
            return;
        }
        try {
            if (!contextValue.provider) {
                showError('Unsupported browser', 'error:noProviderDetected');
                return;
            }
            if (!address) {
                return;
            }

            const initialStable = config.currency.find(
                (el) => el.type === EToken.STABLE
            );
            const initialCrypto = config.currency.find(
                (el) => el.type === EToken.CRYPTO
            );

            if (!initialStable || !initialCrypto) {
                return;
            }

            fetchCurrency(initialCrypto.address);
            fetchCurrency(initialStable.address);
        } catch (err) {
            showUnhandledError(err);
        }
    };

    useEffect(() => {
        if (!contextValue.provider) {
            showError('Unsupported browser', 'error:noProviderDetected');
            return;
        }
        contextValue.provider.on(
            'network',
            (_newNetwork: unknown, oldNetwork: unknown) => {
                // following best practices https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
                if (oldNetwork) {
                    window.reload();
                }
            }
        );

        (contextValue.provider.provider as typeof window.ethereum).on(
            'accountsChanged',
            (_accounts: string[]) => {
                updateSignerData();
                return;
            }
        );

        updateSignerData();

        return () => {
            contextValue.provider?.removeAllListeners();
        };
    }, []);

    useEffect(() => {
        fetchInitialPortfolio();
    }, [address, isValidNetwork]);

    const fetchPrices = async () => {
        const getPrices = await getCurrencyPrice();
        if (getPrices) {
            await setPrices(getPrices);

            setIsInitialized(true);
        }
    };

    useEffect(() => {
        fetchPrices();
    }, [isInitialized]);
    return (
        <WalletContext.Provider
            value={{
                ...contextValue,
                connectWallet,
                prices,
                isConnected,
                isValidNetwork,
                address,
                switchNetwork: () => switchToValidNetwork(),
                getPortfolioAmount: (currency: TCurrencyType) =>
                    portfolio[currency] || 0,
                portfolio,
                fetchCurrency,
            }}
        >
            {isErrorVisible && (
                <ErrorModal
                    title={errorTitle}
                    message={errorMessage}
                    close={() => setIsErrorVisible(false)}
                />
            )}
            {isRulesModalVisible && (
                <RulesModal
                    close={() => setIsRulesModalVisible(false)}
                    connectWallet={() => connectWallet(true)}
                ></RulesModal>
            )}
            {children}
        </WalletContext.Provider>
    );
};
