import { ECryptoType, EStableType } from '../../types/currency';
import { ETransactionType } from '../../types/DTO';
import { formatDecimal } from '../../utils/numbers';
import { IWalletContext } from '../wallet/context';
import { ISwapAccessor } from './ISwapAccessor';
import { WalletAccessor } from './WalletAccessor';

export class SellAccessor extends WalletAccessor implements ISwapAccessor {
    public constructor() {
        super();
    }

    public GetMinPrice(
        wallet: IWalletContext,
        pair: string,
        _: number
    ): number {
        return formatDecimal(this.GetActualPrice(wallet, pair), 2);
    }

    public GetSliderDefaultValues(): number[] {
        return [0, 2.5, 5, 7.5, 10, 12.5, 15];
    }

    public GetAmountToken(
        wallet: IWalletContext,
        cryptoCurrency: ECryptoType,
        stableCurrency: EStableType,
        percentAmount: number
    ): number {
        const amountTokens =
            this.getBalanceCrypto(wallet, cryptoCurrency) *
            (percentAmount / 100);

        return formatDecimal(amountTokens);
    }

    public GetTransactionType(): ETransactionType {
        return ETransactionType.SELL;
    }

    GetAskAmount(amount: number, price: number): number {
        return formatDecimal(amount * price);
    }

    GetCurrency(
        cryptoCurrency: ECryptoType,
        stableType: EStableType
    ): ECryptoType | EStableType {
        return stableType;
    }

    public GetSwapType(): string {
        return 'sell';
    }
}
