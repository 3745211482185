import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IStakingDetails } from '../types';
import { StakingBox } from './StakingBox';

export const StakingList: React.FC<{ stakingList: Array<IStakingDetails> }> = ({
    stakingList,
}) => {
    return (
        <div className="w-full box col-span-12 mt-12">
            <div className="text-3xl font-semibold mx-2 mt-6">
                <FormattedMessage id="activeStakes" />
            </div>
            {stakingList
                .sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        const result =
                            a.stakingStartDate > b.stakingStartDate ? -1 : 1;
                        return result;
                    } else {
                        return +b.isActive - +a.isActive;
                    }
                })

                .map((staking) => (
                    <StakingBox
                        stakingDetails={staking}
                        key={staking.stakingAddress}
                    />
                ))}
        </div>
    );
};
