import { IConfig } from 'config';
import { ECryptoType, EStableType, EToken } from 'types/currency';

export const smartChain: IConfig = {
    network: {
        name: 'ethereum',
        chainId: '0x1',
        rpcUrl: 'https://test/',
        blockExplorerUrl: 'https://test/',
        symbol: 'BNB',
        symbolDigits: 18,
    },
    appName: 'Ethereum chain',
    timeSettlementValues: [1, 3, 7, 14, 28], // in days
    ledgerManager: '0xad888871DF832c9513A54b86C5A7588De5A12E26',
    nativeTokenAddress: '0xb7bd5886163a36d3BC4C24679A2B777ec7ABa110',
    nativeTokenName: 'BRI',
    currency: [
        {
            name: EStableType.TUSD,
            address: '0x0aCCB71D3215d63C784dC9985CD8F64E64b997CE',
            type: EToken.STABLE,
        },
        {
            name: ECryptoType.TETH,
            address: '0x0000000000000000000000000000000000000000',
            type: EToken.CRYPTO,
        },
    ],
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
};
