// import { Header } from 'components/Header';
import React from 'react';
import { Header } from './Header';
import ellipse from '../../assets/ellipse.png';
import { ReactComponent as Shape1 } from '../../assets/shape1.svg';
import { ReactComponent as Shape2 } from '../../assets/shape2.svg';

export const Layout: React.FC = ({ children }) => {
    return (
        <div className="relative">
            <div className="max-w-screen-xl mx-auto px-3 lg:px-10 z-20">
                <Header />
                {children}
            </div>
            <div className="absolute top-32 left-20" style={{ zIndex: -1 }}>
                <Shape1 />
            </div>
            <div
                className="absolute right-20"
                style={{ top: 1000, zIndex: -1 }}
            >
                <Shape2 />
            </div>
            <img
                src={ellipse}
                alt="ellipse"
                className="absolute top-0"
                style={{ zIndex: '-1' }}
            />
            <img
                src={ellipse}
                alt="ellipse"
                className="absolute right-0 -top-72"
                style={{ zIndex: '-1', transform: 'rotate(180deg)' }}
            />
        </div>
    );
};
