import axios from 'axios';
import { ethers } from 'ethers';
import { IOrderBook } from 'types/orderBook';

interface IOrder {
    type: 'Buy' | 'Sell';
    maturityTime: number;
    askAsset: string;
    offerAsset: string;
    placementDate: number;
    deposited: string;
    price: number;
    reward: string;
    active: boolean;
    id: number;
}

interface IOrderResponse {
    orders: IOrder[];
}

const orderMapper = (v: IOrder): IOrderBook => {
    return {
        id: v.id,
        type: v.type,
        maturityTime: v.maturityTime * 1000,
        pair: `${v.askAsset}/${v.offerAsset}`,
        placementDate: v.placementDate * 1000,
        deposited: +ethers.utils.formatEther(v.deposited),
        price: v.price,
        reward: +v.reward,
        active: v.active,
    };
};

export const getOrderHistory = async (): Promise<Array<IOrderBook>> => {
    try {
        const response = await axios.get<IOrderResponse>(
            `${process.env.REACT_APP_BASE_URL}/orders/history`
        );

        return response.data.orders.map(orderMapper);
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const getMyHistory = async (
    address: string
): Promise<Array<IOrderBook>> => {
    try {
        const response = await axios.get<IOrderResponse>(
            `${process.env.REACT_APP_BASE_URL}/orders/history/${address}`
        );

        return response.data.orders.map(orderMapper);
    } catch (err) {
        console.log(err);
        return [];
    }
};
