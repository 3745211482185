import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNativeToken } from '../../../../hooks/useNativeToken';
import { SwapContext } from '../../context';
import { InternalSwapState } from '../InternalSwapState';
import { TextInfo } from './TextInfo';

interface IProps {
    minLabel: string;
    rewardLabel: string;
}

export const SummarySwapInfo: React.FC<IProps> = ({ minLabel }) => {
    const { formatMessage } = useIntl();
    const swapContext = useContext(SwapContext);
    const { name } = useNativeToken();
    return (
        <div className="border-darkpool-lighter border-t mt-5 pt-5">
            <TextInfo
                label={formatMessage({ id: minLabel })}
                value={`~${swapContext.getAskAmount()} ${swapContext.getCurrency()}`}
                tooltip="minimumReceivedTooltip"
            />
            <TextInfo
                label={formatMessage({
                    id:
                        swapContext.swapState == InternalSwapState.Left
                            ? 'bookingFee'
                            : 'yourReward',
                })}
                value={`~${
                    swapContext.swapState == InternalSwapState.Left
                        ? swapContext.reward
                        : (swapContext.reward * 1.063).toFixed(4)
                } ${name}`}
                tooltip="yourRewardTooltip"
                marked
            />
            <TextInfo
                label={formatMessage({ id: 'apr' })}
                value={`~${swapContext.getApr().toFixed(2)}%`}
                // #APR Value Calculation = ((BRI reward * BRI Spot price) * (365/maturity period))/Order Value
                // for now we dont have bri spot price
                // TODO
                // tooltip="aprTooltip"
            />
            <TextInfo
                label={formatMessage({ id: 'transactionFee' })}
                value="0 %"
                tooltip="transactionFeeTooltip"
            />
        </div>
    );
};
