import React, { useEffect, useRef } from 'react';

export const useDidMountEffect = (
    callback: () => void,
    deps: React.DependencyList | undefined
): void => {
    const didMount = useRef(false); // nosemgrep

    useEffect(() => {
        if (didMount.current) {
            callback();
        } else {
            didMount.current = true;
        }
    }, deps);
};
