import { useConfig } from 'config/context';
import { useSigner } from 'hooks/useSigner';
import { WalletContext } from 'modules/wallet/context';
import { ERC20Contract } from 'modules/wallet/utils/ERC20Contract';
import React, { useContext, useEffect, useState } from 'react';
import { getErc20Balance } from 'utils/getErc20Balance';
import { StakingContract } from '../wallet/utils/StakingContract';
import { getStakingAddresses } from './api';
import { IStakingDetails } from './types';

const defaultValues = {
    initialized: false,
    stakingList: [] as Array<IStakingDetails>,
    balance: 0,
    isPendingAction: false,
    getReward: async (_stakingAddress: string): Promise<void> => {
        return;
    },
    stakeToken: async (
        _stakingAddress: string,
        _amount: number
    ): Promise<void> => {
        return;
    },
    unstakeToken: async (
        _stakingAddress_: string,
        _amount: number
    ): Promise<void> => {
        return;
    },
};

export const StakingContext = React.createContext(defaultValues);

export const StakingProvider: React.FC = ({ children }) => {
    const { signer, address } = useSigner();
    const { nativeTokenAddress: bp1Token } = useConfig();
    const { isValidNetwork } = useContext(WalletContext);
    const [fetchDate, setFetchDate] = useState(new Date());

    const [stakingList, setStakingList] = useState<Array<IStakingDetails>>([]);

    const [balance, setBalance] = useState(0);
    const [isPendingAction, setIsPendingAction] = useState(false);

    useEffect(() => {
        if (address && isValidNetwork) {
            (async () => {
                const nativeBalance = await getErc20Balance(bp1Token);
                setBalance(nativeBalance);
                const stakingAddresses: Array<string> =
                    await getStakingAddresses();

                const mappedStakings = await Promise.all(
                    stakingAddresses.map(
                        async (stakingAddress): Promise<IStakingDetails> => {
                            const stakingContract = new StakingContract(
                                signer,
                                stakingAddress
                            );
                            const secondsSinceStart =
                                await stakingContract.secondsSinceStart();
                            const totalPeriodSeconds =
                                await stakingContract.totalPeriodSeconds();
                            const progress = Math.ceil(
                                (secondsSinceStart / totalPeriodSeconds) * 100
                            );

                            const currentIndex =
                                await stakingContract.totalFactor();
                            const stakingStartDate =
                                await stakingContract.stakingStartDate();
                            const stakingEndDate =
                                await stakingContract.stakingEndDate();
                            const stakingPoolSize =
                                await stakingContract.totalStaked();
                            const currentReward =
                                await stakingContract.calculateReward(address);
                            const stakedBalance =
                                await stakingContract.stakedBalance(address);
                            const currentAPR =
                                await stakingContract.currentAPR();

                            const isActive =
                                totalPeriodSeconds - secondsSinceStart > 0;

                            return {
                                currentReward,
                                totalPeriodSeconds,
                                secondsSinceStart,
                                progress,
                                stakingStartDate,
                                stakingEndDate,
                                stakedBalance,
                                stakingAddress,
                                currentAPR,
                                currentIndex,
                                stakingPoolSize,
                                isActive,
                            };
                        }
                    )
                );

                setStakingList(mappedStakings);
            })();
        }
    }, [address, isValidNetwork, fetchDate]);

    const stakeToken = async (
        stakingAddress: string,
        amount: number
    ): Promise<void> => {
        const stakingContract = new StakingContract(signer, stakingAddress);
        const erc20Contract = new ERC20Contract(signer, bp1Token);

        try {
            setIsPendingAction(true);
            if (
                (await erc20Contract.getAllowance(stakingAddress)).lte(amount)
            ) {
                const approveTx = await erc20Contract.approve(stakingAddress);
                await approveTx.wait();
            }

            const stakeTx = await stakingContract.stake(amount);
            await stakeTx.wait();

            setTimeout(() => {
                setFetchDate(new Date());
                setIsPendingAction(false);
            }, 5000);
        } catch (err) {
            console.error(err);
            setIsPendingAction(false);
        }
        return;
    };

    const getReward = async (stakingAddress: string): Promise<void> => {
        const stakingContract = new StakingContract(signer, stakingAddress);
        try {
            setIsPendingAction(true);

            const getRewardTx = await stakingContract.getRewardAndUnstake();
            await getRewardTx.wait();

            setTimeout(() => {
                setFetchDate(new Date());
                setIsPendingAction(false);
            }, 5000);
        } catch (err) {
            console.error(err);
            setIsPendingAction(false);
        }
        return;
    };

    const unstakeToken = async (
        stakingAddress: string,
        amount: number
    ): Promise<void> => {
        const stakingContract = new StakingContract(signer, stakingAddress);

        try {
            setIsPendingAction(true);
            const stakeTx = await stakingContract.unstake(amount);
            await stakeTx.wait();

            setTimeout(() => {
                setFetchDate(new Date());
                setIsPendingAction(false);
            }, 5000);
        } catch (err) {
            console.error(err);
            setIsPendingAction(false);
        }
        return;
    };

    return (
        <StakingContext.Provider
            value={{
                initialized: true,
                balance,
                stakingList,
                stakeToken,
                unstakeToken,
                getReward,
                isPendingAction,
            }}
        >
            {children}
        </StakingContext.Provider>
    );
};

export const useStakingContext = (): typeof defaultValues => {
    const ctx = useContext(StakingContext);

    if (!ctx || !ctx.initialized) {
        throw new Error(
            'useStakingContext must be used within a StakingProvider'
        );
    }

    return ctx;
};
