export enum EOrderBookMode {
    MY_TRANSACTION,
    ALL_MARKET,
}

export interface IOrderBook {
    id: number | string;
    type: 'Buy' | 'Sell';
    maturityTime: number;
    pair: string;
    placementDate: number;
    deposited: number;
    price: number;
    reward: number;
    active: boolean;
}
