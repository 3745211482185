import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

export const addSettlement = (days: number): Date => {
    const date = new Date();

    return addDays(date, days);
};

export const formatToDate = (date: Date): string => {
    return format(date, 'dd.MM.yyyy');
};

export const formatToDateTime = (date: Date): string => {
    return format(date, 'dd.MM.yyyy HH:ss');
};

export const settlementTimeToSecs = (days: number): number =>
    days * 24 * 60 * 60;

export const formatDateUnits = (
    seconds: number
): { amount: number; unit: 'minutes' | 'hours' | 'days' } => {
    if (seconds < 3600) {
        //1 hour
        return { amount: Math.floor(seconds / 60), unit: 'minutes' };
    }
    if (seconds < 86400) {
        //24 hours
        return { amount: Math.floor(seconds / 3600), unit: 'hours' };
    }

    return { amount: Math.floor(seconds / 86400), unit: 'days' };
};
