import { TCryptoCurrency, TStableCurrency } from '../../config';
import { useCurrency } from '../../hooks/useCurrency';
import {
    ECryptoType,
    EStableType,
    TCurrencyPair,
    TCurrencyType,
} from '../../types/currency';
import { formatDecimal } from '../../utils/numbers';
import { IWalletContext } from '../wallet/context';

export abstract class WalletAccessor {
    protected cryptoContext: {
        cryptoNames: ECryptoType[];
        stableNames: EStableType[];
        cryptoToken: TCryptoCurrency[];
        stableToken: TStableCurrency[];
        getAddressByName: (_: TCurrencyType) => string;
    };

    protected constructor() {
        this.cryptoContext = useCurrency();
    }

    public GetActualPrice(wallet: IWalletContext, pair: string): number {
        return wallet.prices[pair as TCurrencyPair] || 0;
    }

    public GetPair(
        cryptoType: ECryptoType,
        stableType: EStableType
    ): TCurrencyPair {
        return `${cryptoType}${stableType}` as TCurrencyPair;
    }

    public GetPrice(wallet: IWalletContext, pair: TCurrencyPair): number {
        return wallet.prices[pair] || 0;
    }

    public FetchCurrencies(
        wallet: IWalletContext,
        cryptoCurrency: ECryptoType,
        stableCurrency: EStableType
    ): void {
        wallet.fetchCurrency(
            this.cryptoContext.getAddressByName(cryptoCurrency)
        );
        wallet.fetchCurrency(
            this.cryptoContext.getAddressByName(stableCurrency)
        );
    }

    protected getBalanceCrypto(
        wallet: IWalletContext,
        cryptoCurrency: ECryptoType
    ): number {
        const amount = wallet.portfolio[cryptoCurrency] || 0;

        return formatDecimal(amount);
    }

    protected getBalanceStable(
        wallet: IWalletContext,
        stableCurrency: EStableType
    ): number {
        const amount = wallet.portfolio[stableCurrency] || 0;

        return formatDecimal(amount);
    }
}
