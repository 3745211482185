import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StakingForm } from './components/StakingForm';
import { StakingList } from './components/StakingList';
import { StakingStatistics } from './components/StakingStatistics';
import { useStakingContext } from './context';

export const StakingHome: React.FC = () => {
    const { stakingList } = useStakingContext();

    const activeStaking = stakingList
        .filter((v) => v.isActive)
        .sort((a, b) => +b.stakingStartDate - +a.stakingStartDate)[0];

    return (
        <div className="container mx-auto">
            <div className="text-3xl font-semibold mx-2 mt-6">
                <FormattedMessage id="staking" />
            </div>
            {activeStaking && (
                <>
                    <StakingStatistics activeStaking={activeStaking} />
                    <StakingForm activeStaking={activeStaking} />
                </>
            )}
            <StakingList stakingList={[...stakingList]} />
        </div>
    );
};
