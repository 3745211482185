import { ethers } from 'ethers';
import { ContractContext } from 'types/Erc20';
import abi from 'types/raw/Erc20.abi.json';

export class ERC20Contract {
    contract: ContractContext;

    constructor(
        private readonly signer: ethers.Signer,
        private readonly address: string
    ) {
        this.address = address;

        this.contract = new ethers.Contract(
            this.address,
            abi,
            this.signer
        ) as unknown as ContractContext;
        return;
    }

    approve = async (
        spenderAddress: string
    ): Promise<ethers.ContractTransaction> => {
        return this.contract.approve(
            spenderAddress,
            ethers.constants.MaxUint256
        );
    };

    getAllowance = async (
        spenderAddress: string
    ): Promise<ethers.BigNumber> => {
        return this.contract.allowance(
            await this.signer.getAddress(),
            spenderAddress
        );
    };
}
