import { useContext } from 'react';
import { WalletContext } from 'modules/wallet/context';
import { ethers } from 'ethers';

export const useSigner = (): {
    address: string;
    signer: ethers.Signer;
    provider: ethers.providers.Provider;
} => {
    const { provider, address } = useContext(WalletContext);

    const signer = provider?.getSigner();
    if (!signer || !provider) {
        throw new Error('signer or provider dont exist');
    }

    return { address, signer, provider };
};
