import React, { ReactElement, useEffect, useState } from 'react';
import FormattedMessage from 'react-intl/lib/src/components/message';
import { isNullish } from 'utils/isNullish';

interface IProps<CurrencyType extends string> {
    name: string;
    amount: number;
    availableValues: Array<CurrencyType>;
    currency: CurrencyType;
    onChangeCurrency: (value: CurrencyType) => void;
    onChangePrice: (value: string) => void;
    minValue?: number;
    maxValue?: number;
    disabled?: boolean;
    balance?: number;
    price?: number;
}

export const Currency = <CurrencyType extends string>({
    name,
    availableValues,
    amount,
    onChangePrice,
    onChangeCurrency,
    currency,
    disabled,
    minValue = -Infinity,
    maxValue = Infinity,
    balance,
    price,
}: IProps<CurrencyType>): ReactElement => {
    const [value, setValue] = useState(String(amount));
    const [touched, setTouched] = useState(false);

    const handleChange = (value: string) => {
        if (!value) {
            setValue('0');
        }
        const val = value.replace(',', '.');
        const lastEl = val[val.length - 1];
        const indexDot = val.indexOf('.');

        if (lastEl === '-') {
            return;
        }

        if (lastEl === '.' && indexDot !== val.length - 1) {
            return;
        }

        if (typeof Number(lastEl) === 'number' || lastEl === '.') {
            setValue(val.replace(',', '.'));
        }
    };

    useEffect(() => {
        setValue(String(amount));
    }, [amount]);

    useEffect(() => {
        if (+value < minValue || !value) {
            setValue(String(minValue));
        }
        if (+value > maxValue) {
            setValue(String(maxValue));
        }
        onChangePrice(value);
        setTouched(false);
    }, [touched]);

    return (
        <div className="mt-2 bg-black p-3 border border-darkpool-light1 rounded-md">
            <div className="flex justify-between">
                <input
                    type="text"
                    className="w-8/12"
                    disabled={disabled}
                    name={name}
                    id={name}
                    value={value}
                    min={minValue}
                    pattern="[0-9]+([\.][0-9]{1,2})?"
                    placeholder="0"
                    onChange={(e) => handleChange(e.currentTarget.value)}
                    onBlur={() => setTouched(true)}
                    max={maxValue}
                />
                <select
                    name="currency"
                    value={currency}
                    onChange={(e) =>
                        onChangeCurrency(e.currentTarget.value as CurrencyType)
                    }
                >
                    {availableValues.map((value) => (
                        <option value={value} key={`${name}_${value}`}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>

            {(!isNullish(price) || !isNullish(balance)) && (
                <div className="text-xs text-darkpool-lightGray flex justify-between mt-2">
                    <span>{price && `~${price}`} </span>
                    <span>
                        {balance && (
                            <>
                                <FormattedMessage id="balance" /> {balance}
                            </>
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};
