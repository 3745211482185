import { InfoTooltip } from 'components/InfoTooltip';
import React from 'react';

interface IProps {
    label: string;
    value: string;
    tooltip?: string;
    marked?: boolean;
}

export const TextInfo: React.FC<IProps> = ({
    label,
    value,
    tooltip,
    marked,
}) => {
    return (
        <div className="flex justify-between text-sm my-2">
            <div className="text-darkpool-lightGray flex items-center">
                {label}
                {tooltip && <InfoTooltip text={tooltip} />}
            </div>
            <span
                className={`font-bold px ${
                    marked ? 'bg-darkpool-green text-black p-1 rounded' : ''
                }`}
            >
                {value}
            </span>
        </div>
    );
};
