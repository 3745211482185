import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SwapButton: React.FC<{
    title: string;
    onClick: () => void;
    selected: boolean;
}> = ({ title, onClick, selected }) => {
    return (
        <button
            className={`w-1/2 flex justify-center items-center rounded-full h-8 text-black font-bold ${
                !selected ? ' dark:bg-black text-white' : ''
            }`}
            disabled={selected}
            onClick={onClick}
        >
            <FormattedMessage id={title} />
        </button>
    );
};
