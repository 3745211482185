import React from 'react';
import { useSettlementTime } from 'hooks/useSettlementTime';
import { useIntl } from 'react-intl';

interface IProps {
    onChange: (number: number) => void;
}

export const TimeSettlement: React.FC<IProps> = ({ onChange }) => {
    const settlementTime = useSettlementTime();
    const intl = useIntl();

    const formatDay = (day: number) => {
        if (day === 1) {
            return `${day} ${intl.formatMessage({ id: 'day' })}`;
        }
        return `${day} ${intl.formatMessage({ id: 'days' })}`;
    };

    return (
        <div>
            <select
                className="w-full"
                onChange={(e) => onChange(+e.currentTarget.value)}
            >
                {settlementTime.map((el) => (
                    <option value={el} key={el}>
                        {formatDay(el)}
                    </option>
                ))}
            </select>
        </div>
    );
};
