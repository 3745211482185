import { NumberInput } from 'components/NumberInput';
import { useNativeToken } from 'hooks/useNativeToken';
import { Slider } from 'modules/swap/components/form/Slider';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStakingContext } from '../context';
import { IStakingDetails } from '../types';

export const StakingForm: React.FC<{ activeStaking: IStakingDetails }> = ({
    activeStaking,
}) => {
    const { name: nativeTokenName } = useNativeToken();

    const { balance, stakeToken, isPendingAction } = useStakingContext();

    const [stakeAmount, setStakeAmount] = useState(0);
    const [percent, setPercent] = useState(0);
    const [estimatedApy, setEstimatedApy] = useState(1000);

    const maxCap = 900000;
    const minCap = 1;

    useEffect(() => {
        setStakeAmount(Math.floor((percent / 100) * balance));
    }, [percent]);

    useEffect(() => {
        setEstimatedApy(Math.floor(stakeAmount * activeStaking.currentAPR));
    }, [stakeAmount]);

    useEffect(() => {
        setPercent(0);
    }, [balance]);

    return (
        <div className="w-full box col-span-12">
            <div className="text-3xl font-semibold mx-2 mt-6">
                <FormattedMessage id="stake" />
            </div>
            <div className="w-full mt-8 mb-2 text-left">
                <FormattedMessage id="stakeFormDescription" />
            </div>
            <div className="w-full flex justify-between mt-8 mb-2">
                <div className="font-bold">
                    <FormattedMessage id="lockedAmount" />
                </div>
                <div className="text-darkpool-lightGray hidden md:block">
                    <FormattedMessage id="balance" />: {balance}{' '}
                    {nativeTokenName}
                </div>
            </div>
            <div className="w-full mb-4">
                <NumberInput
                    onChange={setStakeAmount}
                    max={balance}
                    value={stakeAmount}
                    defaultValue={0}
                    suffix={[nativeTokenName]}
                />
            </div>
            <div className="w-full mb-8">
                <Slider
                    value={percent}
                    onChange={(value) => {
                        setPercent(value);
                    }}
                    sliderValues={[0, 25, 50, 75, 100]}
                    step={0.1}
                />
            </div>
            <div className="w-full">
                <div className="w-full grid grid-cols-2 my-2 md:hidden">
                    <div className="text-darkpool-lightGray">
                        <FormattedMessage id="balance" />
                    </div>
                    <div>
                        <div className="text-right text-darkpool-lightGray">
                            <span className="font-bold text-white">
                                {balance} {nativeTokenName}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 my-2">
                    <div className="text-darkpool-lightGray">
                        <FormattedMessage id="lockedAmountCaps" />
                    </div>
                    <div>
                        <div className="text-right text-darkpool-lightGray">
                            <FormattedMessage id="max" />
                            {': '}
                            <span className="font-bold text-white">
                                {maxCap} {nativeTokenName}
                            </span>
                        </div>
                        <div className="text-right text-darkpool-lightGray">
                            <FormattedMessage id="min" />
                            {': '}
                            <span className="font-bold text-white">
                                {minCap} {nativeTokenName}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 my-2">
                    <div className="text-darkpool-lightGray">
                        <FormattedMessage id="estimatedApy" />
                    </div>
                    <div className="text-right text-darkpool-lightGray">
                        <span className="font-bold text-white">
                            {estimatedApy} {nativeTokenName}
                        </span>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 my-2">
                    <div className="text-darkpool-lightGray">
                        <FormattedMessage id="estimatedInterestRate" />
                    </div>
                    <div className="text-right text-darkpool-lightGray">
                        <span className="font-bold text-white">
                            {activeStaking.currentAPR * 100}%
                        </span>
                    </div>
                </div>
            </div>

            <div className="block pt-8 pb-6">
                {isPendingAction ? (
                    <button
                        className="btn block dark:bg-darkpool-gray w-full px-4 py-4 uppercase"
                        disabled={isPendingAction}
                    >
                        <FormattedMessage id="pending" />
                    </button>
                ) : (
                    <button
                        className="btn block dark:bg-darkpool-accent w-full px-4 py-4 uppercase"
                        onClick={() =>
                            stakeToken(
                                activeStaking.stakingAddress,
                                stakeAmount
                            )
                        }
                    >
                        <FormattedMessage id="stake" />
                    </button>
                )}
            </div>
        </div>
    );
};
