import { useNativeToken } from 'hooks/useNativeToken';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDateUnits, formatToDateTime } from 'utils/date';
import { formatDecimal } from 'utils/numbers';
import { useStakingContext } from '../context';
import { IStakingDetails } from '../types';

const detailRow = (title: string, text: string | JSX.Element) => {
    return (
        <div className="flex justify-between">
            <div className="text-darkpool-lightGray">{title}</div>
            <div className="font-bold text-right">{text}</div>
        </div>
    );
};

export const StakingBox: React.FC<{ stakingDetails: IStakingDetails }> = (
    props
) => {
    const intl = useIntl();
    const {
        secondsSinceStart,
        totalPeriodSeconds,
        progress,
        currentReward,
        stakedBalance,
        stakingStartDate,
        stakingEndDate,
        stakingAddress,
        isActive,
    } = props.stakingDetails;

    const { name: nativeTokenName } = useNativeToken();
    const nativeTokenPrice = 0.01;

    const { unstakeToken, getReward, isPendingAction } = useStakingContext();

    const startUnits = formatDateUnits(totalPeriodSeconds - secondsSinceStart);

    return (
        <div>
            <div className="w-full bg-darkpool-dimmed rounded-xl my-8 p-4">
                <div className="flex mb-4 justify-between">
                    <div>
                        <div className="mb-2 text-darkpool-lightGray font-semibold">
                            <FormattedMessage id="currentRewardValue" />
                        </div>
                        <div>
                            <span className="text-3xl font-bold">
                                {formatDecimal(currentReward, 6)}{' '}
                                {nativeTokenName}
                            </span>{' '}
                            <span className="text-darkpool-lightGray">
                                ~$
                                {formatDecimal(
                                    currentReward * nativeTokenPrice,
                                    2
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        {isActive && stakedBalance > 0 && (
                            <button
                                className="btn block border border-darkpool-border uppercase py-2 px-8"
                                onClick={() =>
                                    unstakeToken(stakingAddress, stakedBalance)
                                }
                                disabled={isPendingAction}
                            >
                                <FormattedMessage id="unstake" />
                            </button>
                        )}
                        {!isActive && currentReward > 0 && (
                            <button
                                className="btn block border dark:bg-darkpool-accent border-darkpool-border uppercase py-2 px-8"
                                onClick={() => getReward(stakingAddress)}
                                disabled={isPendingAction}
                            >
                                <FormattedMessage id="claimReward" />
                            </button>
                        )}
                    </div>
                </div>
                <div className="w-full flex justify-between grid-cols-2 mt-8">
                    <div className="text-darkpool-lightGray">
                        <FormattedMessage id="unlocksIn" />:
                    </div>{' '}
                    {isActive ? (
                        <div className="font-bold">
                            {startUnits.amount}{' '}
                            <FormattedMessage id={startUnits.unit} />
                        </div>
                    ) : (
                        <div className="font-bold">
                            <FormattedMessage id="ready" />
                        </div>
                    )}
                </div>
                <div className="w-full bg-black rounded-full h-2.5 dark:bg-black mt-2 mb-8">
                    <div
                        className="bg-darkpool-accent h-2.5 rounded-full"
                        style={{ width: isActive ? progress + '%' : '100%' }}
                    ></div>
                </div>
                {detailRow(
                    intl.formatMessage({ id: 'stakingDate' }),
                    formatToDateTime(stakingStartDate)
                )}
                {!isActive &&
                    detailRow(
                        intl.formatMessage({ id: 'stakingFinishDate' }),
                        formatToDateTime(stakingEndDate)
                    )}
                {detailRow(
                    intl.formatMessage(
                        { id: 'nativeTokensStaked' },
                        { nativeTokenName }
                    ),
                    <>
                        {stakedBalance} {nativeTokenName}
                        <div className="text-darkpool-lightGray font-normal text-right text-sm">
                            ~$
                            {formatDecimal(stakedBalance * nativeTokenPrice, 2)}
                        </div>
                    </>
                )}
                <div className="flex justify-between md:hidden">
                    {isActive && stakedBalance > 0 && (
                        <button
                            className="btn block w-full border border-darkpool-border uppercase py-2 px-8 mt-8"
                            onClick={() =>
                                unstakeToken(stakingAddress, stakedBalance)
                            }
                            disabled={isPendingAction}
                        >
                            <FormattedMessage id="unstake" />
                        </button>
                    )}
                    {!isActive && currentReward > 0 && (
                        <button
                            className="btn block w-full border dark:bg-darkpool-accent border-darkpool-border uppercase py-2 px-8 mt-8"
                            onClick={() => getReward(stakingAddress)}
                            disabled={isPendingAction}
                        >
                            <FormattedMessage id="claimReward" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
