import React from 'react';
import { IOrderBook } from 'types/orderBook';
import date from 'date-and-time';
import { ReactComponent as Trash } from '../../../../assets/trash.svg';
import { ReactComponent as SquareArrow } from '../../../../assets/squareArrow.svg';

export const Row: React.FC<{
    order: IOrderBook;
}> = ({ order }) => {
    return (
        <tbody>
            <tr className="border-b border-darkpool-dimmed">
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-6">
                    {order.pair}
                </th>
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-6">
                    {order.type}
                </th>
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-6">
                    {date.format(new Date(order.placementDate), 'DD.MM.YYYY')}
                </th>
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-6">
                    {date.format(new Date(order.maturityTime), 'DD.MM.YYYY')}
                </th>
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-2">
                    {order.deposited.toFixed(2)}
                </th>
                <th className="text-sm text-left px-2 text-darkpool-lighterGray py-6">
                    {(+order.price).toFixed(2)}
                </th>
                <th className="text-sm  text-left px-2 text-darkpool-lighterGray py-6">
                    {(+order.reward).toFixed(6)}
                </th>
                <th className="text-sm text-left mx-2 text-darkpool-lighterGray py-6">
                    <div className="flex justify-center items-center cursor-pointer w-9 h-9 mx-auto">
                        <Trash />
                    </div>
                </th>
                <th className="text-sm text-left mx-2 text-darkpool-lighterGray py-6">
                    <div className="flex justify-center items-center cursor-pointer w-9 h-9 mx-auto">
                        <SquareArrow />
                    </div>
                </th>
            </tr>
        </tbody>
    );
};
