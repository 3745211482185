import React from 'react';
import { StakingProvider } from './context';
import { StakingHome } from './StakingHome';

export const Staking: React.FC = () => {
    return (
        <StakingProvider>
            <StakingHome />
        </StakingProvider>
    );
};
