import { config as conf, IConfig } from 'config';
import React, { useContext, useEffect, useState } from 'react';

interface IConfigMethods {
    setConfigByNetwork: (network: number) => boolean;
    getFirstValidNetwork: () => IConfig;
}

const initialState: IConfig = {
    network: {
        name: '',
        chainId: '',
        rpcUrl: '',
        blockExplorerUrl: '',
        symbol: '',
        symbolDigits: 0,
    },
    appName: '',
    nativeTokenName: '',
    timeSettlementValues: [],
    nativeTokenAddress: '',
    ledgerManager: '',
    currency: [],
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
};

export const Config = React.createContext<IConfig | undefined>(undefined);
const SetConfig = React.createContext<IConfigMethods | undefined>(undefined);

const findConfig = (networkId: number): IConfig | undefined => {
    return conf.find((el) => Number(el.network.chainId) === Number(networkId));
};

const getFirstValidNetwork = (): IConfig => {
    return conf[0];
};

export const ConfigProvider: React.FC = ({ children }) => {
    const [config, setConfig] = useState<IConfig>(initialState);

    useEffect(() => {
        setConfig(conf[0]);
    }, []);

    const setConfigByNetwork = (networkId: number): boolean => {
        const conf = findConfig(networkId);

        if (!conf) {
            return false;
        }
        setConfig(conf);
        return true;
    };
    return (
        <SetConfig.Provider
            value={{ setConfigByNetwork, getFirstValidNetwork }}
        >
            <Config.Provider value={config}>{children}</Config.Provider>
        </SetConfig.Provider>
    );
};

export const useConfigMethod = (): IConfigMethods => {
    const ctx = useContext(SetConfig);

    if (!ctx) {
        throw new Error(
            'Component beyond ConfigProvider state context, please connect'
        );
    }

    return ctx;
};

export const useConfig = (): IConfig => {
    const ctx = useContext(Config);

    if (!ctx) {
        throw new Error(
            'Component beyond ConfigProvider state context, please connect'
        );
    }

    return ctx;
};
