import { BigNumber, ethers } from 'ethers';
import abi from 'types/raw/Staking.abi.json';
import { ContractContext } from 'types/Staking';

const formatUnits = (value: BigNumber): number => {
    return (
        Math.round(Number(ethers.utils.formatUnits(value).toString()) * 100) /
        100
    );
};

export class StakingContract {
    contract: ContractContext;

    constructor(
        private readonly signer: ethers.Signer,
        private readonly address: string
    ) {
        this.address = address;

        this.contract = new ethers.Contract(
            this.address,
            abi,
            this.signer
        ) as unknown as ContractContext;
        return;
    }

    totalStaked = async (): Promise<number> => {
        return formatUnits(await this.contract.totalStaked());
    };

    totalFactor = async (): Promise<number> => {
        return formatUnits(await this.contract.totalFactor());
    };

    totalReward = async (): Promise<number> => {
        return formatUnits(await this.contract.totalReward());
    };

    private stakedBalanceRaw = async (address: string): Promise<BigNumber> => {
        return this.contract.balances(address);
    };

    stakedBalance = async (address: string): Promise<number> => {
        return formatUnits(await this.stakedBalanceRaw(address));
    };

    stakingFactor = async (address: string): Promise<number> => {
        return formatUnits(await this.contract.stakingFactor(address));
    };

    currentAPR = async (): Promise<number> => {
        const address = await this.signer.getAddress();
        try {
            const reward = await this.calculateRewardRaw(address);
            const stakedBalance = await this.stakedBalanceRaw(address);

            const result = stakedBalance.mul(100).div(reward.mul(100));

            return result.toNumber();
        } catch (err) {
            console.log(err);
            return 0;
        }
    };

    private calculateRewardRaw = async (
        address: string
    ): Promise<BigNumber> => {
        return this.contract.calculateReward(address);
    };

    calculateReward = async (address: string): Promise<number> => {
        try {
            return formatUnits(await this.calculateRewardRaw(address));
        } catch (err) {
            console.warn(err);
            return 0;
        }
    };

    stakingStartDate = async (): Promise<Date> => {
        return new Date(
            BigNumber.from(await this.contract.stakingStart()).toNumber() * 1000
        );
    };

    stakingEndDate = async (): Promise<Date> => {
        return new Date(
            BigNumber.from(await this.contract.stakingEnd()).toNumber() * 1000
        );
    };

    secondsSinceStart = async (): Promise<number> => {
        const start = await this.stakingStartDate();
        const now = new Date();
        const diff = now.getTime() - start.getTime();
        return Math.ceil(diff / 1000);
    };

    daysSinceStart = async (): Promise<number> => {
        return Math.ceil((await this.secondsSinceStart()) / (3600 * 24));
    };

    totalPeriodSeconds = async (): Promise<number> => {
        const start = await this.stakingStartDate();
        const end = await this.stakingEndDate();
        const diff = end.getTime() - start.getTime();
        return Math.ceil(diff / 1000);
    };

    totalPeriodDays = async (): Promise<number> => {
        return Math.ceil((await this.totalPeriodSeconds()) / (3600 * 24));
    };

    daysLeft = async (): Promise<number> => {
        const start = new Date();
        const end = await this.stakingEndDate();
        const diff = end.getTime() - start.getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    };

    stake = async (amount: number): Promise<ethers.ContractTransaction> => {
        const parsedAmount = ethers.utils.parseUnits(amount.toString(), 18);
        return this.contract.stake(parsedAmount);
    };

    unstake = async (amount: number): Promise<ethers.ContractTransaction> => {
        const parsedAmount = ethers.utils.parseUnits(amount.toString(), 18);
        return this.contract.unstake(parsedAmount);
    };

    getRewardAndUnstake = async (): Promise<ethers.ContractTransaction> => {
        return this.contract.getRewardAndUnstake();
    };
}
