export const en = {
    actionRejected: 'Action rejected',
    amount: 'Amount',
    amountFrom: 'Amount',
    buy: 'Buy',
    Buy: 'Buy',
    book: 'BOOK',
    confirmAndBuyX: 'Confirm and buy {amount} {currency}',
    confirmAndSellX: 'Confirm and sell {amount} {currency}',
    confirmOrder: 'Confirm order',
    connectedWallet: 'Wallet',
    connectWallet: 'Connect wallet',
    connectToWallet: 'Connect to a wallet',
    disconnectFromWallet: 'Disconnect from wallet',
    modalErrorButton: 'Got it',
    currencyBalance: '{currency} balance',
    nativePrice: 'DPX price',
    price: 'Price',
    priceImpact: 'Price impact',
    pricePerToken: 'Price per token',
    yourTotalCost: 'Your total Cost',
    reward: 'Reward',
    sell: 'Sell',
    Sell: 'Sell',
    order: 'ORDER',
    switchNetwork: 'Switch to valid network',
    timeSettlement: 'Time settlement',
    timeSettlementUnit: 'D',
    youPay: 'You pay',
    youBuy: 'You buy',
    youRecieve: 'You receive',
    readRules: 'Read rules',
    acceptRules: 'Accept rules',
    iAcceptTheTerms: 'I accept the terms of service and privacy policy',
    read: 'Read',
    and: 'and',
    termsOfService: 'Terms of service',
    privacyPolicy: 'Privacy policy',

    error: 'Error',
    'error:switchNetworkRejected':
        'Please switch your network to the {network}',
    'error:walletConnectionRejected':
        'Please accept connection request with your wallet',
    'error:noProviderDetected': 'No metamask detected',
    activeOrder: 'Active order',
    closedOrder: 'Closed order',
    pair: 'Pair',
    type: 'Type',
    placementDate: 'Date of placement',
    maturityTime: 'Maturity time',
    deposited: 'Deposited',
    dashboard: 'Dashboard',
    staking: 'Staking',
    showFullDashboard: 'Show full dashboard',
    treasuryPoll: 'Treasury Pool',
    stakingPool: 'Staking pool',
    volume: 'Volume',
    currentAPR: 'Current APR',
    stakingPoolSize: 'Staking pool size',
    currentIndex: 'Current index',
    yourCurrentReward: 'Your current reward (value)',
    youStake: 'You stake',
    yourBalance: 'Your balance',
    yourStakedBalance: 'Your staked balance',
    daysLeft: 'days left',
    stakeToken: 'Stake {token}',
    unstakeToken: 'Unstake {token}',
    stake: 'Stake',
    unstake: 'Unstake',
    day: 'Day',
    days: 'Days',
    connect: 'Connect',
    expiresIn: 'Expires in',
    balance: 'Balance',
    minimumReceived: 'Minimum received',
    yourReward: 'Your reward',
    bookingFee: 'Booking fee',
    slippage: 'Slippage',
    transactionFee: 'Transaction fee',
    myTransaction: 'My transaction',
    marketTransaction: 'Market transaction',
    tradingPair: 'Trading pair',
    createdDate: 'Created',
    expires: 'Expires',
    expirationDate: 'Expiration date',
    rate: 'Rate',
    apr: 'APR',
    briReward: 'BRI Reward',
    cancelOrder: 'Cancel Order',
    transactionId: 'Transaction ID',
    stakeFormDescription:
        'Ada held on the Cardano network represents a stake in the network, with the size of the stake proportional to the amount of ada held. The ability to delegate or pledge a stake is fundamental to how Cardano works.',
    activeStakes: 'Active stakes',
    lockedAmount: 'Locked amount',
    min: 'Min',
    max: 'Max',
    lockedAmountCaps: 'Locked amount caps',
    estimatedInterestRate: 'Estimated interest rate',
    estimatedApy: 'Estimated APY',
    currentRewardValue: 'Current reward (value)',
    unlocksIn: 'Unlocks in',
    nativeTokensStaked: '{nativeTokenName} tokens staked',
    stakingDate: 'Staking date',
    stakingFinishDate: 'Staking finish date',
    ready: 'Ready',
    claimReward: 'Claim reward',
    pending: 'Pending',
    buyTokens: 'Buy tokens',
    sellTokens: 'Sell tokens',
    confirm: 'Confirm',
    cancel: 'Cancel',
    priceTooltip:
        'This is the price at which you want to place the buy/sell order.',
    expiresInTooltip:
        'This is the Maturity period at the end of which your order will be executed.',
    minimumReceivedTooltip:
        'The amount of assets you will receive after the successful order settlement.',
    yourRewardTooltip: 'The amount of BRI tokens you receive for this order',
    transactionFeeTooltip:
        'Transaction Fee on Brightpool.Finance is always zero. Instead, users receive rewards for placing orders.',
    aprTooltip: '',
    myOrders: 'My orders',
    marketOrders: 'Market orders',
};
