import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Alert } from './icons/Alert.svg';
import { Modal } from './Modal';

export const ErrorModal: React.FC<{
    title: string;
    message: string;
    close: () => void;
}> = ({ title, message, close }) => {
    return (
        <Modal close={close}>
            <div className="flex justify-center mb-8 -mt-14">
                <Alert className="w-24 text-red-400" />
            </div>
            <div
                className="text-xl text-center mb-4"
                data-cy="error-modal-title"
            >
                {title && <FormattedMessage id={title} />}
            </div>
            <div>{message && <FormattedMessage id={message} />}</div>
            <button
                className="w-full rounded-none mt-8 py-4 dark:bg-red-400"
                onClick={close}
            >
                <FormattedMessage id="modalErrorButton" />
            </button>
        </Modal>
    );
};
