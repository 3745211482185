import date from 'date-and-time';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IOrderBook } from 'types/orderBook';

interface IProps extends IOrderBook {
    onCancel: () => void;
    getTransaction: () => void;
}

export const MobileOrderBook: React.FC<IProps> = (props) => {
    return (
        <div className="text-sm">
            <div className="flex justify-between mt-4">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="tradingPair" />
                </span>

                <span className="text-white font-bold">{props.pair}</span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="type" />
                </span>

                <span
                    className={`font-bold ${
                        props.type === 'Sell'
                            ? 'text-darkpool-red'
                            : 'text-darkpool-green'
                    }`}
                >
                    {props.type}
                </span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="createdDate" />
                </span>

                <span className="text-white font-bold">
                    {date.format(new Date(props.placementDate), 'DD.MM.YYYY')}
                </span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="expirationDate" />
                </span>

                <span className="text-white font-bold">
                    {date.format(new Date(props.maturityTime), 'DD.MM.YYYY')}
                </span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="amount" />
                </span>

                <span className="text-white font-bold">
                    {props.deposited.toFixed(2)}
                </span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="price" />
                </span>

                <span className="text-white font-bold">{props.price}</span>
            </div>
            <div className="flex justify-between mt-2">
                <span className="text-darkpool-lightGray">
                    <FormattedMessage id="briReward" />
                </span>

                <span className="text-white font-bold">
                    {props.reward.toFixed(2)}
                </span>
            </div>
            <button
                onClick={props.onCancel}
                className="w-full dark:bg-black border border-darkpool-dimmed rounded-full py-3 mt-3"
            >
                <FormattedMessage id="cancelOrder" />
            </button>
            <button
                onClick={props.getTransaction}
                className="w-full dark:bg-black border border-darkpool-dimmed rounded-full py-3 mt-3"
            >
                <FormattedMessage id="transactionId" />
            </button>
        </div>
    );
};
