import { InfoTooltip } from 'components/InfoTooltip';
import { WalletContext } from 'modules/wallet/context';
import React, { useContext, useState } from 'react';
import FormattedMessage from 'react-intl/lib/src/components/message';
import { ECryptoType, EStableType } from 'types/currency';
import { formatDecimal } from 'utils/numbers';
import { SwapContext } from '../context';
import { ConfirmationModal } from './ConfirmationModal';
import { Currency } from './form/Currency';
import { Slider } from './form/Slider';
import { SummarySwapInfo } from './form/SummarySwapInfo';
import { TimeSettlement } from './form/TimeSettlement';
import { InternalSwapState } from './InternalSwapState';
import { SwapModeToggle } from './SwapModeToggle';

export const SwapForm: React.FC = () => {
    const swapContext = useContext(SwapContext);
    const walletContext = useContext(WalletContext);

    const [showModal, setShowModal] = useState(false);

    const getBalanceCrypto = (): number => {
        return formatDecimal(
            walletContext.portfolio[swapContext.cryptoCurrency] || 0
        );
    };

    const getBalanceStable = (): number => {
        return formatDecimal(
            walletContext.portfolio[swapContext.priceCurrency] || 0
        );
    };

    const getMaxValue = () => {
        if (swapContext.mode === InternalSwapState.Left) {
            const balance = getBalanceStable();
            const price = swapContext.price;
            return formatDecimal(balance / price);
        }
        return getBalanceCrypto();
    };

    const getMaxPrice = (): number => {
        if (swapContext.mode === InternalSwapState.Left) {
            return swapContext.getActualPrice();
        }
        const percent = // nosemgrep
            swapContext.sliderValuesPrice[
                swapContext.sliderValuesPrice.length - 1
            ];
        return (
            swapContext.getActualPrice() +
            swapContext.getActualPrice() * (percent / 100)
        );
    };

    const handleChangePriceBuyMode = (value: string): void => {
        const val = +value;
        const price = formatDecimal(val, 2);
        const stablePrice = swapContext.getActualPrice();

        if (!stablePrice) {
            return;
        }

        const percent = ((stablePrice - price) / stablePrice) * 100 * -1;
        const minValue = swapContext.getMinPrice();

        if (price < minValue) {
            swapContext.setPrice(minValue);
            swapContext.setPercentPrice(swapContext.sliderValuesPrice[0]);
            const amountTokens =
                (getBalanceStable() / minValue) * swapContext.percentAmount;
            swapContext.setAmount(amountTokens);
            return;
        }
        const calcPrice = stablePrice < price ? stablePrice : price;
        swapContext.setPrice(calcPrice);
        swapContext.setPercentPrice(
            stablePrice < price
                ? swapContext.sliderValuesPrice[
                      swapContext.sliderValuesPrice.length - 1
                  ]
                : percent
        );
        const amountTokens =
            ((getBalanceStable() / calcPrice) * swapContext.percentAmount) /
            100;
        swapContext.setAmount(formatDecimal(amountTokens));
    };

    const handleChangePriceSellMode = (value: string): void => {
        const val = Number(value);
        const price = formatDecimal(val, 2);
        const stablePrice = swapContext.getActualPrice();

        if (!stablePrice) {
            return;
        }

        const percent = ((price - stablePrice) / stablePrice) * 100;
        const maxPrice = getMaxPrice();
        if (price > maxPrice) {
            swapContext.setPrice(maxPrice);
            swapContext.setPercentPrice(
                swapContext.sliderValuesPrice[
                    swapContext.sliderValuesPrice.length - 1
                ]
            );
            return;
        }
        swapContext.setPrice(price < stablePrice ? stablePrice : price);
        swapContext.setPercentPrice(
            price < stablePrice
                ? swapContext.sliderValuesPrice[
                      swapContext.sliderValuesPrice.length - 1
                  ]
                : percent
        );
    };

    const handleChangePercentBuyMode = (value: number) => {
        const valueAbs = Math.abs(value);
        const price = swapContext.getActualPrice();
        const valueDifference = (valueAbs / 100) * price;
        swapContext.setPercentPrice(value);

        if (value < 0) {
            const calcPrice = formatDecimal(Number(price - valueDifference));
            const amountTokens =
                (getBalanceStable() / calcPrice) *
                (swapContext.percentAmount / 100);
            swapContext.setPrice(formatDecimal(calcPrice, 2));
            swapContext.setAmount(formatDecimal(+amountTokens));
            return;
        }
        if (value === 0) {
            swapContext.setPrice(price);
            const amountTokens =
                (getBalanceStable() / price) *
                (swapContext.percentAmount / 100);
            swapContext.setAmount(formatDecimal(amountTokens));

            return;
        }
    };

    const handleChangePercentSellMode = (value: number) => {
        const price = swapContext.getActualPrice();
        const valueDifference = (value / 100) * price;
        swapContext.setPercentPrice(value);
        if (value > 0) {
            swapContext.setPrice(
                formatDecimal(Number(price + valueDifference), 2)
            );
            return;
        }
        if (value === 0) {
            swapContext.setPrice(formatDecimal(price, 2));
            return;
        }
    };

    const handleChangeAmountPercentSellMode = (percent: number) => {
        const balance = getBalanceCrypto();

        const num = formatDecimal(balance * (percent / 100));
        swapContext.setAmount(num);
        swapContext.setPercentAmount(percent);
    };

    const handleChangeAmountPercentBuyMode = (percent: number) => {
        const balance = getBalanceStable();
        const price = swapContext.price;
        const something = balance / price;
        const amountTokens = something * (percent / 100);

        swapContext.setAmount(formatDecimal(amountTokens));
        swapContext.setPercentAmount(percent);
    };

    const handleChangeAmountSellMode = (value: string): void => {
        const balance = getBalanceCrypto();

        if (+value <= 0) {
            swapContext.setPercentAmount(0);
            swapContext.setAmount(0);
            return;
        }
        if (+value > balance) {
            swapContext.setAmount(Number(balance));
            swapContext.setPercentAmount(100);
            return;
        }
        const percent = Math.round((+value / balance) * 100);
        swapContext.setAmount(formatDecimal(+value));
        swapContext.setPercentAmount(percent);
    };

    const handleChangeAmountBuyMode = (value: string) => {
        const balance = getBalanceStable();
        const price = swapContext.price;
        const maxTokens = balance / price;

        if (+value <= 0) {
            swapContext.setPercentAmount(0);
            swapContext.setAmount(0);
            return;
        }

        if (+value > maxTokens) {
            swapContext.setAmount(formatDecimal(maxTokens));
            swapContext.setPercentAmount(100);
            return;
        }
        const percent = Math.round((+value / maxTokens) * 100);
        swapContext.setAmount(formatDecimal(+value));
        swapContext.setPercentAmount(percent);
    };

    const handleClickShowModal = (): void => {
        const { price, amount } = swapContext;

        if (!price || !amount) {
            return;
        }
        setShowModal(true);
    };

    const getOfferAmount = () => {
        if (swapContext.mode === InternalSwapState.Left) {
            return formatDecimal(swapContext.amount * swapContext.price);
        }
        return formatDecimal(swapContext.amount);
    };

    function handleClickChangeMode(mode: InternalSwapState) {
        if (mode === swapContext.mode) {
            return;
        }
        swapContext.setMode(mode);
        swapContext.setAmount(0);
        swapContext.setPercentAmount(0);
    }

    return (
        <div className="w-full">
            {showModal && (
                <ConfirmationModal
                    close={() => setShowModal(false)}
                    confirm={() => setShowModal(false)}
                    askAmount={swapContext.getAskAmount()}
                    offerAmount={getOfferAmount()}
                />
            )}

            <SwapModeToggle
                left={'Buy'}
                right={'Sell'}
                onChange={handleClickChangeMode}
            />

            <div className="mt-6">
                <div className="text-sm font-bold flex">
                    <FormattedMessage id="price" />
                    <InfoTooltip text="priceTooltip" />
                </div>
                <Currency<EStableType>
                    name="price"
                    availableValues={swapContext.priceSelect}
                    onChangePrice={
                        swapContext.mode === InternalSwapState.Left
                            ? handleChangePriceBuyMode
                            : handleChangePriceSellMode
                    }
                    amount={swapContext.price || 0}
                    minValue={swapContext.getMinPrice()}
                    maxValue={getMaxPrice()}
                    onChangeCurrency={(value) =>
                        swapContext.setPriceCurrency(value)
                    }
                    currency={swapContext.priceCurrency}
                    balance={walletContext.portfolio[swapContext.priceCurrency]}
                />
                <div className="px-1 mt-2">
                    <Slider
                        value={swapContext.percentPrice}
                        onChange={
                            swapContext.mode === InternalSwapState.Left
                                ? handleChangePercentBuyMode
                                : handleChangePercentSellMode
                        }
                        sliderValues={swapContext.sliderValuesPrice}
                        minus={swapContext.mode === InternalSwapState.Left}
                        step={0.1}
                    />
                </div>
            </div>
            <div className="mt-6">
                <div className="text-sm font-bold">
                    <FormattedMessage id="amount" />
                </div>
                <Currency<ECryptoType>
                    name="amount"
                    availableValues={swapContext.amountSelect}
                    onChangePrice={
                        swapContext.mode === InternalSwapState.Left
                            ? handleChangeAmountBuyMode
                            : handleChangeAmountSellMode
                    }
                    amount={swapContext.amount || 0}
                    onChangeCurrency={(value) => {
                        swapContext.setCryptoCurrency(value);
                    }}
                    minValue={0}
                    maxValue={getMaxValue()}
                    currency={swapContext.cryptoCurrency}
                    balance={
                        walletContext.portfolio[swapContext.cryptoCurrency]
                    }
                />

                <div className="px-1 mt-2">
                    <Slider
                        value={swapContext.percentAmount}
                        onChange={
                            swapContext.mode === InternalSwapState.Left
                                ? handleChangeAmountPercentBuyMode
                                : handleChangeAmountPercentSellMode
                        }
                        sliderValues={swapContext.sliderValuesAmount}
                        step={1}
                    />
                </div>
            </div>
            <div className="text-sm font-bold mt-5 flex">
                <FormattedMessage id="expiresIn" />
                <InfoTooltip text="expiresInTooltip" />
            </div>
            <div className="mt-2">
                <TimeSettlement onChange={swapContext.setSettlement} />
            </div>
            <SummarySwapInfo
                minLabel={'minimumReceived'}
                rewardLabel={'yourReward'}
            />
            <div className="block pt-8 pb-4">
                <button
                    className="w-full text-black rounded-full py-3 font-bold"
                    onClick={handleClickShowModal}
                >
                    <FormattedMessage
                        id={
                            swapContext.mode === InternalSwapState.Left
                                ? 'buy'
                                : 'sell'
                        }
                    />
                </button>
            </div>
        </div>
    );
};
